import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './api.service';

import { BehaviorSubject, tap } from 'rxjs';
import { AuthService } from '@services/storage/auth.service';

@Injectable({
	providedIn: 'root',
})
export class PreferencesService {
	public onChange: EventEmitter<any> = new EventEmitter();
	public preferencesOfCompany: BehaviorSubject<any> = new BehaviorSubject({});
	public preferences: any = undefined;
	constructor(private api: ApiService, private _authSrc: AuthService) {}

	getPreferences(company_id: string = this._authSrc.getInfo()?.company as string) {
		return this.api.get(`/c/${company_id}/pref`).pipe(
			tap((preferences) => {
				this.preferencesOfCompany.next(preferences);
			})
		);
	}

	getPreferencesNew(company_id: string = this._authSrc.getInfo()?.company as string) {
		return this.api.get(`/c/${company_id}/pref`);
	}

	enableDisablePreferences(company_id: string, module: any) {
		return this.api.post(`/c/${company_id}/pref/module`, module);
	}

	updatePreferences(company_id: string, module: any) {
		return this.api.put(`/c/${company_id}/pref/edit`, module);
	}

	getCustomFields(company_id: string): any | Promise<any> {
		return this.api.get(`/c/${company_id}/pref/fields`);
	}
	setContactForm(data: any): any | Promise<any> {
		return this.api.put(`/c/${this._authSrc.getInfo()?.company as string}/pref/contact/form`, {
			state: data.state,
			additionalFields: data.additionalFields,
		});
	}
	getFormsFields(company_id: string): any | Promise<any> {
		return this.api.get(`/c/${company_id}/pref/forms`);
	}
	addFormsFields(company_id: string, content: any): any | Promise<any> {
		return this.api.post(`/c/${company_id}/pref/forms/add`, content);
	}
	getMemberships(company_id: string, membershipId?: string): any | Promise<any> {
		return membershipId
			? this.api.get(`/c/${company_id}/memberships/${membershipId}`).toPromise()
			: this.api.get(`/c/${company_id}/memberships`);
	}

	getUrlApp(id = this.preferencesOfCompany.getValue().appCC) {
		return this.api.get(`/apps/url/${id}`);
	}
}
