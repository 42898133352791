import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VlIconModule } from '@components_shared/vl-icon/vl-icon.module';
import { WithThesePermissionModule } from '@directives/with-these-permission/with-these-permission.module';

@Component({
	selector: 'menu-item',
	templateUrl: './menu-item.component.html',
	styleUrls: ['./menu-item.component.scss'],
	standalone: true,
	imports: [VlIconModule, RouterModule, CommonModule, WithThesePermissionModule],
})
export class MenuItemComponent implements OnInit {
	@Input('config') config: {
		label: string;
		iconName: string;
		params?: object;
		url: Array<string>;
		mode: 'item' | 'subItem';
		permissions: Array<string>;
	} = {
		label: '',
		iconName: '',
		url: [''],
		mode: 'item',
		permissions: ['*'],
	};
	constructor() {}

	ngOnInit(): void {}
}
