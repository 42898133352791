import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, interval, takeUntil } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class WebSocketService {
	private _authSrc: AuthService = inject(AuthService);
	private _localStorageSrc: LocalStorageService = inject(LocalStorageService);
	private _socket!: Socket;
	private _unSuscribeAll: Subject<any> = new Subject();

	status: 'connect' | 'disconnect' = 'disconnect';
	$status: BehaviorSubject<'connect' | 'disconnect'> = new BehaviorSubject<'connect' | 'disconnect'>(this.status);

	constructor() {
		this.connect = this.connect.bind(this);
		this.disconnect = this.disconnect.bind(this);
		this.listen = this.listen.bind(this);
		this.emit = this.emit.bind(this);
	}
	connect() {
		try {
			this._socket = io(environment.wsServer, {
				withCredentials: true,
				timeout: 20000,
				query: {
					data: JSON.stringify({
						agent: false,
						role: 'supervisor',
						token: this._authSrc.getToken(),
						id: this._localStorageSrc.get('socketId') || false,
					}),
				},
			});
			this._unSuscribeAll = new Subject();
			this._socket.connect();
			this._socket.onAny((event: string, ...args: any[]) => {
				console.log({ event, args });
			});
			this.listen('connect').subscribe(() => {
				this.changeStatusSocket('connect');
				this.listen('id').subscribe((data) => {
					this._localStorageSrc.set('socketId', data.id);
				});
				interval(5000)
					.pipe(takeUntil(this._unSuscribeAll))
					.subscribe(() => {
						this._socket.emit('ping', { data: { user: this._authSrc.getInfo()?.user, origin: 'Admin' } });
					});
			});
			this.listen('disconnect').subscribe(() => {
				this.changeStatusSocket('disconnect');
				this._unSuscribeAll.next(true);
				this._unSuscribeAll.complete();
			});
		} catch (error) {
			console.log({ error });
		}
	}
	disconnect() {
		this._socket.close();
		this._socket.disconnect();
	}

	// listen(event: string): any {
	listen(event: string): Observable<any> {
		return new Observable((sub) => {
			this._socket.on(event, (data: any) => {
				sub.next(data);
			});
		}).pipe(takeUntil(this._unSuscribeAll));
	}

	emit(event: string, data: any = {}): void {
		this._socket.emit(event, data);
	}

	changeStatusSocket(newStatus: 'connect' | 'disconnect') {
		this.status = newStatus;
		this.$status.next(this.status);
		console.log({ status: this.status });
	}
}
