<main>
    <div
    (click)="closeDialog()" class="close-container">
    <svg (click)="closeDialog()" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <path d="M6.06575 5.66617C5.96369 5.66617 5.86175 5.70526 5.78357 5.78251C5.62813 5.93554 5.62813 6.1905 5.78357 6.34594L11.9815 12.5415C12.1361 12.6945 12.3895 12.6945 12.5449 12.5415C12.6996 12.3884 12.6996 12.1311 12.5449 11.978L6.347 5.78251C6.26975 5.70526 6.16769 5.66617 6.06562 5.66617H6.06575Z" fill="#757575"/>
        <path d="M12.2647 5.6671C12.1626 5.6671 12.0606 5.70619 11.9825 5.78344L5.78455 11.98C5.62911 12.133 5.62911 12.3904 5.78455 12.5434C5.93998 12.6965 6.19254 12.6965 6.34798 12.5434L12.5459 6.3479C12.7013 6.19246 12.7013 5.93748 12.5459 5.78446C12.4687 5.70641 12.3674 5.66732 12.2645 5.66732L12.2647 5.6671Z" fill="#757575"/>
        <path d="M9.16504 8.3528e-07C4.11134 8.3528e-07 0 4.11237 0 9.16504C0 14.2194 4.11168 18.3301 9.16504 18.3301C14.2184 18.3301 18.3301 14.2194 18.3301 9.16504C18.3301 4.11237 14.2184 8.3528e-07 9.16504 8.3528e-07V8.3528e-07ZM9.16504 17.5332C4.55058 17.5332 0.796856 13.7802 0.796856 9.16504C0.796856 4.55127 4.55058 0.796857 9.16504 0.796857C13.7795 0.796857 17.5332 4.55127 17.5332 9.16504C17.5332 13.7802 13.7795 17.5332 9.16504 17.5332Z" fill="#757575"/>
        </svg>
    </div>
    <div class="limiter">
      <div class="content" style="max-width: 1171px">
  
        <div class="preview-container {{dataPreviewer.nameStyle}}">
            <section class="preview-section">
              <div class="preview-content">
                <div
                  class="pre-banner"
                  [ngStyle]="{
                    'background-color': dataPreviewer.colorBackground
                  }" 
                  >
                  <h1 *ngIf="dataPreviewer.nameStyle == 'mobile'" class="title-banner">
                    Bienvenido a 
                  </h1>
                  <div class="logo-container">
                    <img [src]="dataPreviewer.file"  alt="logo" />
                    
                  </div>
                  <div class="button-container">
                    <button
                      class="button-tour"
                      [ngStyle]="{
                        'background-color': dataPreviewer.colorButton,
                        color: dataPreviewer.colorText
                      }"
                    >
                      Ver tour
                    </button>
                  </div>
                </div>
                <div class="pre-content">
                  <h3 class="title-pre">
                    {{
                      dataPreviewer.title ||
                        'Texto de ejemplo de título para Contact Center'
                    }}
                  </h3>
                  <p class="p-prev">
                    {{
                      dataPreviewer.description ||
                        'Texto de descripción para el Contact Center'
                    }}
                  </p>
                  <ng-container *ngIf="getScreenWidth > 600; then desktop; else mobile"> </ng-container>
                  <ng-template #desktop>
                    <div class="attention-container">
                      <div class="card-container" *ngFor="let item of typeOfAttentions">
                        <div class="first">
                          <div class="icon-container">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="33"
                              height="33"
                              viewBox="0 0 33 33"
                              fill="none"
                            >
                              <path
                                d="M16.5 2.0625C24.4406 2.0625 30.9375 8.55937 30.9375 16.5C30.9375 24.4406 24.4406 30.9375 16.5 30.9375C8.55937 30.9375 2.0625 24.4406 2.0625 16.5C2.0625 8.55937 8.55937 2.0625 16.5 2.0625ZM16.5 0C7.37314 0 0 7.37314 0 16.5C0 25.6269 7.37314 33 16.5 33C25.6269 33 33 25.6269 33 16.5C33 7.37314 25.6269 0 16.5 0Z"
                                fill="#1e0745"
                              />
                              <path
                                d="M13 12.5C13 13.8808 11.8808 15 10.5 15C9.1192 15 8 13.8808 8 12.5C8 11.1192 9.1192 10 10.5 10C11.8808 10 13 11.1192 13 12.5Z"
                                fill="#1e0745"
                              />
                              <path
                                d="M25 12.5C25 13.8808 23.8808 15 22.5 15C21.1192 15 20 13.8808 20 12.5C20 11.1192 21.1192 10 22.5 10C23.8808 10 25 11.1192 25 12.5Z"
                                fill="#1e0745"
                              />
                              <path
                                d="M24 19C22.6552 21.967 19.8103 24 16.5 24C13.1897 24 10.3448 21.967 9 19"
                                style="stroke: #1e0745"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div class="text-container">
                            <h4 class="title-p-t-c">{{item.name}}</h4>
                            <p class="p-card">{{item.description}}</p>
                          </div>
                        </div>
                        <div class="second">
                          <button
                          [ngStyle]="{
                              'background-color': dataPreviewer.buttonAttentionsColor
                            }"
                          >    
                            {{ dataPreviewer.buttonTitle || 'SELECCIONAR' }} 
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #mobile>
                    <div class="attention-container">
                      <div class="card-container" *ngFor="let item of typeOfAttentions">
                        <div class="first">
                          <div class="icon-container">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="33"
                              height="33"
                              viewBox="0 0 33 33"
                              fill="none"
                            >
                              <path
                                d="M16.5 2.0625C24.4406 2.0625 30.9375 8.55937 30.9375 16.5C30.9375 24.4406 24.4406 30.9375 16.5 30.9375C8.55937 30.9375 2.0625 24.4406 2.0625 16.5C2.0625 8.55937 8.55937 2.0625 16.5 2.0625ZM16.5 0C7.37314 0 0 7.37314 0 16.5C0 25.6269 7.37314 33 16.5 33C25.6269 33 33 25.6269 33 16.5C33 7.37314 25.6269 0 16.5 0Z"
                                fill="#1e0745"
                              />
                              <path
                                d="M13 12.5C13 13.8808 11.8808 15 10.5 15C9.1192 15 8 13.8808 8 12.5C8 11.1192 9.1192 10 10.5 10C11.8808 10 13 11.1192 13 12.5Z"
                                fill="#1e0745"
                              />
                              <path
                                d="M25 12.5C25 13.8808 23.8808 15 22.5 15C21.1192 15 20 13.8808 20 12.5C20 11.1192 21.1192 10 22.5 10C23.8808 10 25 11.1192 25 12.5Z"
                                fill="#1e0745"
                              />
                              <path
                                d="M24 19C22.6552 21.967 19.8103 24 16.5 24C13.1897 24 10.3448 21.967 9 19"
                                style="stroke: #1e0745"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div class="text-container">
                            <h4 class="title-p-t-c">{{item.name}}</h4>
                            <p class="p-card">{{item.description}}</p>
                          </div>
                        </div>
                        <div class="second">
                          <button
                          [ngStyle]="{
                              'background-color': dataPreviewer.buttonAttentionsColor
                            }"
                          >    
                            {{ dataPreviewer.buttonTitle || 'SELECCIONAR' }} 
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="help-container">
                    <div class="one-line">
                      <p>¿Necesitas ayuda?</p>
                      <a>Contáctanos </a>
                    </div>
                    <!-- <div class="space-content"></div> -->
                  </div>
                </div>
              </div>
            </section>
          </div>
      </div>
 
    </div>
</main>
