import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'vl-range',
	templateUrl: './vl-range.component.html',
	styleUrls: ['./vl-range.component.scss'],
})
export class VlRangeComponent implements OnInit {
	@Input() rangeDisable: number[] = [];
	@Input() value: number = 0;
	@Output() changeValue: EventEmitter<number> = new EventEmitter();
	constructor() {}

	ngOnInit(): void {
		console.log(this.value);
	}

	limit($event: any) {
		try {
			let totalInputs =
				this.rangeDisable
					.map((input) => Number(input))
					.filter((input) => !isNaN(input))
					.reduce((prev, curr) => prev + curr, 0) - this.value;

			if ($event.target.value > 100 - Number(totalInputs)) this.value = 100 - Number(totalInputs);
			else this.value = Number($event.target.value);

			$event.target.value = this.value;
			this.changeValue.emit(this.value);

			console.log({ totalInputs });
			console.log(this.value);
		} catch (error) {
			console.log('Un error acaba de pasar');
		}
	}
}
