import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 } from 'uuid';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	public notifications: BehaviorSubject<Array<vlToast>> = new BehaviorSubject<Array<vlToast>>([]);
	constructor() {}
	show({ type = 'success', title, msg, time = 3000, icon = '' }: vlNotification) {
		const groupOfNotification: any = [...this.notifications.getValue(), { id: v4(), type, icon, title, msg, time }];
		this.notifications.next(groupOfNotification);
	}
	quitNotification(id: string) {
		const groupOfNotification = this.notifications.getValue().filter((notification) => notification.id != id);
		this.notifications.next(groupOfNotification);
	}
}
