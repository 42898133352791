<div class="vl-input-file" [ngStyle]="{ 'border-bottom': files.length ? '1px solid #000' : 'none' }">
	<label
		for="file"
		class="vl-input-file__label"
		[ngStyle]="{
			background: !files.length ? '#f5f5f5' : 'white',
			alignItems: !files.length ? 'center' : 'flex-start'
		}"
	>
		<div class="vl-input-file__files" *ngIf="files.length">
			<div class="vl-input-file__chip" *ngFor="let file of files; let i = index">
				<div class="vl-input-file__chip__name">{{ file.name }}</div>

				<div class="vl-input-file__chip__action" (click)="removeFile($event, i)">
					<svg
						class="vl-input-file__chip__action__icon"
						width="12"
						height="12"
						viewBox="0 0 12 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_980_9389)">
							<path
								d="M8.03184 4.1582C8.03184 4.10664 7.98965 4.06445 7.93809 4.06445L7.16465 4.06797L5.9998 5.45664L4.83613 4.06914L4.06152 4.06563C4.00996 4.06563 3.96777 4.10664 3.96777 4.15938C3.96777 4.18164 3.97598 4.20273 3.99004 4.22031L5.51465 6.03672L3.99004 7.85195C3.97588 7.86913 3.96802 7.89063 3.96777 7.91289C3.96777 7.96445 4.00996 8.00664 4.06152 8.00664L4.83613 8.00313L5.9998 6.61445L7.16348 8.00195L7.93691 8.00547C7.98848 8.00547 8.03066 7.96445 8.03066 7.91172C8.03066 7.88945 8.02246 7.86836 8.0084 7.85078L6.48613 6.03555L8.01074 4.21914C8.0248 4.20273 8.03184 4.18047 8.03184 4.1582Z"
								fill="#1B0845"
							/>
							<path
								d="M6 0.761719C3.10078 0.761719 0.75 3.1125 0.75 6.01172C0.75 8.91094 3.10078 11.2617 6 11.2617C8.89922 11.2617 11.25 8.91094 11.25 6.01172C11.25 3.1125 8.89922 0.761719 6 0.761719ZM6 10.3711C3.59297 10.3711 1.64062 8.41875 1.64062 6.01172C1.64062 3.60469 3.59297 1.65234 6 1.65234C8.40703 1.65234 10.3594 3.60469 10.3594 6.01172C10.3594 8.41875 8.40703 10.3711 6 10.3711Z"
								fill="#1B0845"
							/>
						</g>
						<defs>
							<clipPath id="clip0_980_9389">
								<rect width="12" height="12" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</div>
			</div>
		</div>
		<ng-container *ngIf="!files.length">
			<div class="vl-input-file__icon">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M4.81303 8C4.69939 7.95312 4.63405 7.83825 4.65464 7.72168V3.7148V3.54034H3.3677H3.21877C2.98933 3.51851 2.93691 3.42109 3.07789 3.25172C3.91392 2.24371 4.75125 1.23813 5.59411 0.239058C5.86922 -0.0854315 5.99673 -0.0738884 6.28926 0.239058C7.15618 1.20609 8.02224 2.17356 8.8872 3.14156L8.88729 3.14165C8.93532 3.21259 8.97323 3.28935 9 3.36996C8.91762 3.4036 8.83103 3.42686 8.74229 3.43921C8.31825 3.4469 7.89418 3.43921 7.47004 3.43921H7.31175V3.58029V7.72551C7.33037 7.84002 7.26101 7.95096 7.14671 7.9897H4.81302L4.81303 8Z"
						fill="#757575"
					/>
					<path
						d="M0.00730957 8.00008H1.66333V10.5105H10.332V8.00107H11.9928C11.9928 8.03976 12 8.0773 12 8.11159V11.1867C12 11.6928 11.6532 11.9999 11.0868 11.9999H0.902462C0.456064 12.0043 0.141665 11.8032 0.0336011 11.4519C0.0105826 11.3737 -0.000718878 11.2931 3.54047e-05 11.2121V8.09395C3.54047e-05 8.06628 3.55142e-05 8.03761 0.0072342 8L0.00730957 8.00008Z"
						fill="#757575"
					/>
				</svg>
			</div>
			<div class="vl-input-file__text">Adjuntar archivo</div>
			<input
				[multiple]="multiple"
				type="file"
				id="file"
				accept="image/jpeg,image/png,application/pdf"
				class="vl-input-file__input"
				(change)="changeFiles($event)"
				[attr.disabled]="true"
			/>
		</ng-container>
	</label>
	<div class="vl-input-file__hint" *ngIf="!files.length">Formato jpg, pdf y excel. Máximo 10mb</div>
</div>
