import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlIconComponent } from './vl-icon.component';
import { AgentAppComponent } from './icons/agent-app/agent-app.component';
import { SettingComponent } from './icons/setting/setting.component';
import { ChannelComponent } from './icons/channel/channel.component';
import { AtenttionComponent } from './icons/atenttion/atenttion.component';
import { TutorialComponent } from './icons/tutorial/tutorial.component';
import { SupportComponent } from './icons/support/support.component';
import { ConditionsComponent } from './icons/conditions/conditions.component';
import { AnalyticsComponent } from './icons/analytics/analytics.component';
import { ProfileComponent } from './icons/profile/profile.component';
import { AnlSatisfactionComponent } from './icons/anl-satisfaction/anl-satisfaction.component';
import { AnlResolvabilityComponent } from './icons/anl-resolvability/anl-resolvability.component';
import { AnlAttentionsComponent } from './icons/anl-attentions/anl-attentions.component';
import { ContactCenterComponent } from './icons/contact-center/contact-center.component';
import { ConversationsComponent } from './icons/conversations/conversations.component';
import { ContactComponent } from './icons/contact/contact.component';
import { EvaluationsComponent } from './icons/evaluations/evaluations.component';
import { UserComponent } from './icons/user/user.component';
import { GroupComponent } from './icons/group/group.component';
import { TypesAttComponent } from './icons/types-att/types-att.component';
import { SubAttComponent } from './icons/sub-att/sub-att.component';
import { AppComponent } from './icons/app/app.component';
import { StatesComponent } from './icons/states/states.component';
import { RolsComponent } from './icons/rols/rols.component';
import { HistoryComponent } from './icons/history/history.component';
import { AccountComponent } from './icons/account/account.component';
import { InvoicesComponent } from './icons/invoices/invoices.component';
import { PaymentsComponent } from './icons/payments/payments.component';
import { PricingComponent } from './icons/pricing/pricing.component';
import { MatIconModule } from '@angular/material/icon';
import { ArrowBackComponent } from './icons/arrow-back/arrow-back.component';
import { TrashComponent } from './icons/trash/trash.component';
import { InactiveComponent } from './icons/inactive/inactive.component';
import { ActiveComponent } from './icons/active/active.component';
import { EditComponent } from './icons/edit/edit.component';
import { DetailComponent } from './icons/detail/detail.component';
import { IconSelectComponent } from './icons/icon-select/icon-select.component';
import { ToastSuccessComponent } from './icons/toast-success/toast-success.component';
import { ToastErrorComponent } from './icons/toast-error/toast-error.component';
import { ToastWarningComponent } from './icons/toast-warning/toast-warning.component';
import { TicketComponent } from './icons/ticket/ticket.component';
import { ManagerFilesComponent } from './icons/manager-files/manager-files.component';
import { DeleteComponent } from './icons/delete/delete.component';
import { NutComponent } from './icons/nut/nut.component';
import { TicketReadOnlyIconComponent } from './icons/ticketReadOnly/ticketReadOnly.component';
import { VliCopyComponent } from './icons/vli-copy/vli-copy.component';
import { WidgetComponent } from './icons/widget/widget.component';
import { MsgDefaultComponent } from './icons/msg-default/msg-default.component';
import { TransferIconComponent } from './icons/transfer-icon/transfer-icon.component';
import { EndTicketIconComponent } from './icons/end-ticket-icon/end-ticket-icon.component';
import { ReopenIconComponent } from './icons/reopen-icon/reopen-icon.component';
import { ReasingIconComponent } from './icons/reasing-icon/reasing-icon.component';
import { PriorityIconComponent } from './icons/priority-icon/priority-icon.component';

@NgModule({
	declarations: [
		VlIconComponent,
		AgentAppComponent,
		SettingComponent,
		ChannelComponent,
		AtenttionComponent,
		TutorialComponent,
		SupportComponent,
		ConditionsComponent,
		AnalyticsComponent,
		ProfileComponent,
		AnlSatisfactionComponent,
		AnlResolvabilityComponent,
		AnlAttentionsComponent,
		ContactCenterComponent,
		ConversationsComponent,
		ContactComponent,
		EvaluationsComponent,
		UserComponent,
		GroupComponent,
		TypesAttComponent,
		SubAttComponent,
		AppComponent,
		StatesComponent,
		RolsComponent,
		HistoryComponent,
		AccountComponent,
		InvoicesComponent,
		PaymentsComponent,
		PricingComponent,
		ArrowBackComponent,
		TrashComponent,
		InactiveComponent,
		ActiveComponent,
		EditComponent,
		DetailComponent,
		IconSelectComponent,
		ToastSuccessComponent,
		ToastErrorComponent,
		ToastWarningComponent,
		TicketComponent,
		ManagerFilesComponent,
		DeleteComponent,
		NutComponent,
		TicketReadOnlyIconComponent,
  VliCopyComponent,
  WidgetComponent,
  MsgDefaultComponent,
  TransferIconComponent,
  EndTicketIconComponent,
  ReopenIconComponent,
  ReasingIconComponent,
  PriorityIconComponent,
	],
	imports: [CommonModule, MatIconModule],
	exports: [VlIconComponent],
})
export class VlIconModule {}
