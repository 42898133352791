import { Component, HostBinding, OnDestroy, OnChanges, SimpleChanges, Input } from '@angular/core';
import { DimensionsScreenService } from 'src/app/services/backend/dimensions-screen.service';

import { Subject, takeUntil } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
	selector: 'box-detail',
	templateUrl: './box-detail.component.html',
	styleUrls: ['./box-detail.component.scss'],
})
export class BoxDetailComponent implements OnDestroy, OnChanges {
	@HostBinding('class.notData') hostNoData = true;

	@Input() dataSource: any = [];
	@Input() filesData: any = [];

	data: any = [];
	files: string = environment.storage;
	private _unSuscribeAll: Subject<any> = new Subject();
	public screenWidth: number = window.screen.width;

	constructor(private dimensionsSrc: DimensionsScreenService) {
		this.dimensionsSrc.data.pipe(takeUntil(this._unSuscribeAll)).subscribe(({ width }) => (this.screenWidth = width));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['dataSource'] && this.dataSource != null) {
			this.hostNoData = this.dataSource?.length == 0;
			this.data = this.dataSource;
		}
	}
	ngOnDestroy(): void {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}

	getOriginalName(download: string, label: string) {
		const name = this.filesData.find((data: any) => data._id === download).originalname;
		if (name.length > 8) {
			return name.substring(0, 4) + '...' + name.substring(name.length - 4);
		} else {
			return name || label;
		}
	}
}
