import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
  @Input() placeholderText: any
  @Output() searchDataEmit: EventEmitter<any> = new EventEmitter()

  constructor() {}

  ngOnInit(): void {}

  applyFilter(event: any) {
    this.searchDataEmit.emit(event)
  }
}
