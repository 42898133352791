<ng-container [ngTemplateOutlet]="isCustom ? customIcon : materialIcon"></ng-container>
<!-- vlIcon -->
<ng-template #customIcon
	><ng-container [style]="{ 'max-height': '25px' }" [ngComponentOutlet]="currentComponent"></ng-container
></ng-template>
<!-- Angular Material -->
<ng-template #materialIcon>
	<mat-icon>
		{{ name }}
	</mat-icon>
</ng-template>
