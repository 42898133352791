<div
	[ngClass]="['vl-toast', type === 'success' ? 'success' : '', type === 'error' ? 'error' : '', type === 'warning' ? 'warning' : '']"
	(click)="close()"
>
	<vl-icon [ngClass]="['vl-toast__icon']" [name]="icon"></vl-icon>
	<div [ngClass]="['vl-toast__info']">
		<p *ngIf="title !== ''" [ngClass]="['vl-toast__info__title']">{{ title }}</p>
		<p *ngIf="msg !== ''" [ngClass]="['vl-toast__info__msg']">{{ msg }}</p>
	</div>
	<button [ngClass]="['vl-toast__close']" type="button">
		<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.25317 3.06259C3.19924 3.06259 3.14538 3.08325 3.10408 3.12406C3.02195 3.20492 3.02195 3.33963 3.10408 3.42176L6.3788 6.69522C6.4605 6.77607 6.59436 6.77607 6.67649 6.69522C6.75819 6.61436 6.75819 6.47837 6.67649 6.39752L3.40177 3.12406C3.36095 3.08325 3.30703 3.06259 3.2531 3.06259H3.25317Z"
				fill="currentColor"
			/>
			<path
				d="M6.52887 3.064C6.47494 3.064 6.42108 3.08466 6.37977 3.12547L3.10505 6.39947C3.02293 6.48033 3.02293 6.61632 3.10505 6.69717C3.18718 6.77803 3.32062 6.77803 3.40275 6.69717L6.67747 3.42371C6.7596 3.34158 6.7596 3.20686 6.67747 3.12602C6.63665 3.08478 6.58322 3.064 6.52887 3.064Z"
				fill="currentColor"
			/>
			<path
				d="M4.89125 0.0690603C2.22109 0.0690603 0.0488281 2.24186 0.0488281 4.91148C0.0488281 7.58201 2.22127 9.75391 4.89125 9.75391C7.56123 9.75391 9.73367 7.58201 9.73367 4.91148C9.73367 2.24186 7.56123 0.0690603 4.89125 0.0690603ZM4.89125 9.33288C2.45317 9.33288 0.469853 7.34993 0.469853 4.91148C0.469853 2.47376 2.45317 0.490086 4.89125 0.490086C7.32934 0.490086 9.31265 2.47376 9.31265 4.91148C9.31265 7.34993 7.32934 9.33288 4.89125 9.33288Z"
				fill="currentColor"
			/>
		</svg>
	</button>
</div>
