import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { AuthInterceptor } from './interceptors/auth-interceptor.interceptor';
import { CopyDirective } from './directives/copy.directive';
import { ComponentsModule } from '@components/components.module';
import { TourInterceptor } from './interceptors/tour.interceptor';
import { WebSocketService } from '@services/backend/web-socket.service';
import { GetContactNamePipe } from './pipes/get-contact-name.pipe';
import { VlModalModule } from '@components_shared/vl-modal/vl-modal.module';
import { VlLoaderModule } from '@components_shared/vl-loader/vl-loader.module';
import { VlNotificationModule } from '@components_shared/vl-notification/vl-notification.module';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
// import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		ComponentsModule,
		VlModalModule,
		VlLoaderModule,
		VlNotificationModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TourInterceptor,
			multi: true,
		},
		WebSocketService,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
})
export class AppModule {}
