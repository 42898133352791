import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { AuthService } from '@services_shared/global/auth.service';
import { Router } from '@angular/router';
import { LoginService } from '@services_shared/global/login.service';
import { NotificationService } from '@services_shared/global/notification.service';
import { WebSocketService } from '@services_shared/global/web-socket.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private _authSrc: AuthService,
		private _router: Router,
		private _loginSrc: LoginService,
		private notificationSrc: NotificationService,
		private _wsSrc: WebSocketService
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const headers: { [key: string]: string } = {};
		this.setBearerToken(headers);
		this.setContentType(headers, request);

		const req = request.clone({ setHeaders: headers });

		return next.handle(req).pipe(
			tap((res) => {
				if (res instanceof HttpResponse && (res.body?.token || res.body?.data?.token) && !req?.url?.includes(`/apps/`)) {
					this.validateBodyToken(res);
				}
			}),
			catchError((error: HttpErrorResponse) => {
				let errorToSend: HttpErrorResponse | Object = error;

				if (error?.error?.target?.status === 0) error.error.code = 'RED';

				this.errorHandlerWithNotification(error);

				const actionsCustom: any = {
					401: () => {
						errorToSend = {};
						this.notificationSrc.show({ type: 'error', title: '¡Sesión finalizada!', msg: 'Su sesión ha expirado' });
						this._authSrc.clearAll();
						this._wsSrc.disconnect();
						this._router.navigate(['/auth/login']);
					},
					426: () => {
						this._loginSrc.refreshToken();
						errorToSend = {};
					},
				};

				actionsCustom[error.status] && actionsCustom[error.status]();

				return throwError(() => errorToSend);
			})
		);
	}

	setBearerToken(headers: { [key: string]: string }): void {
		const token = this._authSrc.getToken();
		if (!token) return;
		headers['Authorization'] = `videolink ${token}`;
	}

	setContentType(headers: { [key: string]: string }, request: HttpRequest<unknown>): void {
		const isFile = request.headers.has('file');
		if (isFile) return;
		headers['Content-Type'] = `application/json`;
	}

	validateBodyToken(res: HttpResponse<TokenResponse | CustomResponse<TokenResponse>>) {
		const token = (res.body as TokenResponse).token || (res.body as CustomResponse<TokenResponse>).data.token;
		this._authSrc.setToken(token);
	}

	errorHandlerWithNotification(error: HttpErrorResponse) {
		const acceptedCodes: { [key: string]: { title?: string; msg?: string } } = {
			RED: {
				title: 'Error en red',
				msg: 'Verifique el estado de su internet.',
			},
			CREDENCIALS_INVALID: {
				title: 'Credenciales inválidas.',
				msg: 'Por favor, verifique sus credenciales e intente nuevamente.',
			},
			USER_INACTIVE: {
				title: 'Usuario no activo.',
				msg: 'Por favor, póngase en contacto con un administrador.',
			},
			USER_NOT_PRIVILEGES_ADM: {
				title: 'Privilegios insuficientes.',
				msg: 'Usted no cuenta con los permisos suficientes para iniciar sesión en el portal del administrador.',
			},
			DUPLICATED_SESSION_ADM: {
				title: 'Sesión duplicada',
				msg: 'Usted posee ya otra sesión activa en otro administrador.',
			},
			TOKEN_INVALID: {
				title: 'Token no válido.',
				msg: 'El token que usted utilizo no es válido.',
			},
			TOKEN_EXPIRED: {
				title: 'Token expirado.',
				msg: 'El token que usted trata de utilizar a expirado.',
			},
			TOKEN_USED: {
				title: 'Token utilizado.',
				msg: 'El token que usted trata de utilizar ya fue utilizado.',
			},
			TOKEN_DISABLED: {
				title: 'Token no activo.',
				msg: 'El token que usted trata de utilizar no se encuentra activo.',
			},
			ABILITY_HAS_SUBABILITIES: {
				title: 'Tipo de atención no eliminada.',
				msg: 'Existen sub-atenciones que dependen de este tipo de atención.',
			},
			GROUP_HAS_APPS: {
				title: 'Grupo no eliminado.',
				msg: 'Existen aplicaciones que dependen de este grupo.',
			},
			GROUPS_ONLY_EMBE: {
				title: 'Grupos solo para embebidos.',
				msg: 'Todos los grupos seleccionados estan configurado para solo embebidos.',
			},
			ROL_HAS_USERS: {
				title: 'Rol no eliminado.',
				msg: 'Existen usuarios que dependen de este rol.',
			},
			INACTIVE_CREDENCIALS_INVALID: {
				title: 'Usuario desactivado.',
				msg: 'Has superado el intento máximo de inicio de sesión, póngase en contacto con un administrador.',
			},
			BLOCK_CREDENCIALS_INVALID: {
				title: 'Usuario bloqueado',
				msg: 'Por favor, póngase en contacto con un administrador.',
			},
			contact_has_chat: {
				title: 'Contacto no eliminado',
				msg: 'Este contacto posee atenciones relacionadas.',
			},
			email_phone_required: {
				title: 'Datos del formulario incorrectos',
				msg: 'El correo y el teléfono es obligatorio',
			},
			email_already_exist: {
				title: 'Datos del formulario incorrectos',
				msg: 'El correo indicado ya esta asociado a otro contacto',
			},
			phone_already_exist: {
				title: 'Datos del formulario incorrectos',
				msg: 'El teléfono indicado ya esta asociado a otro contacto',
			},
			menu_in_use: {
				title: 'Recurso no eliminado',
				msg: 'Existen tipos de atención que dependen de este menú.',
			},
		};

		if (!acceptedCodes[error.error.code]) return;

		this.notificationSrc.show({ type: 'error', ...acceptedCodes[error.error.code] });
	}
}
