import { Directive } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
	selector: '[formInputTrim]',
})
export class FormInputTrimDirective {
	constructor(private ngcontrol: NgControl) {
		this.trimValue(this.ngcontrol.valueAccessor);
	}

	private trimValue(valueAccesor: any | ControlValueAccessor): void {
		const originalValue = valueAccesor.registerOnChange;

		valueAccesor.registerOnChange = (fn: any) => {
			return originalValue.call(valueAccesor, (value: unknown) => {
				return fn(typeof value === 'string' ? value.trim() : value);
			});
		};
	}
}
