import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TourService } from '@services/components/tour.service';
import { LocalstorageService } from '@services/storage/localstorage.service';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'Steps',
	templateUrl: './steps.component.html',
	styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit, OnDestroy {
	
	@HostListener('window:keyup.arrowLeft', ['$event'])
	onKeyupArrowLeft(event: KeyboardEvent) {
		if (this.isActiveTour && this.data.hasPrev && !this.isActiveModal) {
			this.changeStep('prev');
		}
	}
	@HostListener('window:keyup.arrowRight', ['$event'])
	onKeyupArrowRight(event: KeyboardEvent) {
		if (this.isActiveTour && this.data.hasNext && !this.isActiveModal) {
			this.changeStep('next');
		}
	}

	public isActiveTour: boolean = false;
	private isActiveModal: boolean = false;
	public data = {
		step: 0,
		maxSteps: 0,
		title: '',
		text: '',
		hasPrev: false,
		hasNext: true,
	};
	private _unSuscribeAll: Subject<any> = new Subject();
	constructor(private _tourSrc: TourService, private _localStorageSrc: LocalstorageService, private _router: Router) {
		this._tourSrc.isActive
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap((value) => {
					this.isActiveTour = value;
				})
			)
			.subscribe();

		this._tourSrc.tourActive
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap((tourActive) => {
					this._localStorageSrc.set('progressTour', JSON.stringify(tourActive));
				}),
				tap((tourActive) => {
					const { module, step } = tourActive;

					if (module && step) {
						const contentData = this._tourSrc.dataTour.find((item) => item.module === module);
						if (contentData) {
							const stepsOfModule = contentData?.steps;

							const stepActive = stepsOfModule[step - 1];

							this.data = {
								...this.data,
								step: step,
								maxSteps: stepsOfModule.length,
								title: stepActive.title,
								text: stepActive.text,
								hasPrev: step > 1,
							};
						}
					}
				})
			)
			.subscribe();

		this._tourSrc.actions
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap(({ action, config }) => {
					if (action === 'showModal') {
						this.isActiveModal = true;
					}
					if (action === 'hiddenModal') {
						this.isActiveModal = false;
					}
				})
			)
			.subscribe();
	}

	ngOnInit(): void {}
	ngOnDestroy(): void {}

	changeStep(action: 'next' | 'prev') {
		this._tourSrc.changeStep(action);
	}

	exitTour() {
		this._tourSrc.showModalExit();
	}
}
