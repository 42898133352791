import { OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { AccountComponent } from './icons/account/account.component';
import { ActiveComponent } from './icons/active/active.component';
import { AgentAppComponent } from './icons/agent-app/agent-app.component';
import { AnalyticsComponent } from './icons/analytics/analytics.component';
import { AnlAttentionsComponent } from './icons/anl-attentions/anl-attentions.component';
import { AnlResolvabilityComponent } from './icons/anl-resolvability/anl-resolvability.component';
import { AnlSatisfactionComponent } from './icons/anl-satisfaction/anl-satisfaction.component';
import { AppComponent } from './icons/app/app.component';
import { ArrowBackComponent } from './icons/arrow-back/arrow-back.component';
import { AtenttionComponent } from './icons/atenttion/atenttion.component';
import { ChannelComponent } from './icons/channel/channel.component';
import { ConditionsComponent } from './icons/conditions/conditions.component';
import { ContactCenterComponent } from './icons/contact-center/contact-center.component';
import { ContactComponent } from './icons/contact/contact.component';
import { ConversationsComponent } from './icons/conversations/conversations.component';
import { DetailComponent } from './icons/detail/detail.component';
import { EditComponent } from './icons/edit/edit.component';
import { EvaluationsComponent } from './icons/evaluations/evaluations.component';
import { GroupComponent } from './icons/group/group.component';
import { HistoryComponent } from './icons/history/history.component';
import { IconSelectComponent } from './icons/icon-select/icon-select.component';
import { InactiveComponent } from './icons/inactive/inactive.component';
import { InvoicesComponent } from './icons/invoices/invoices.component';
import { PaymentsComponent } from './icons/payments/payments.component';
import { PricingComponent } from './icons/pricing/pricing.component';
import { RolsComponent } from './icons/rols/rols.component';
import { SettingComponent } from './icons/setting/setting.component';
import { StatesComponent } from './icons/states/states.component';
import { SubAttComponent } from './icons/sub-att/sub-att.component';
import { SupportComponent } from './icons/support/support.component';
import { ToastErrorComponent } from './icons/toast-error/toast-error.component';
import { ToastSuccessComponent } from './icons/toast-success/toast-success.component';
import { ToastWarningComponent } from './icons/toast-warning/toast-warning.component';
import { TrashComponent } from './icons/trash/trash.component';
import { TutorialComponent } from './icons/tutorial/tutorial.component';
import { TypesAttComponent } from './icons/types-att/types-att.component';
import { UserComponent } from './icons/user/user.component';

@Component({
	selector: 'vl-icon',
	templateUrl: './vl-icon.component.html',
	styleUrls: ['./vl-icon.component.scss'],
})
export class VlIconComponent implements OnChanges {
	@Input('name') name: string = '';
	@Input() isCustom?: boolean = true;

	public iconsRef = [
		{ name: 'vli_agentApp', component: AgentAppComponent },
		{ name: 'vli_attention', component: AtenttionComponent },
		{ name: 'vli_analytics', component: AnalyticsComponent },
		{ name: 'vli_channel', component: ChannelComponent },
		{ name: 'vli_conditions', component: ConditionsComponent },
		{ name: 'vli_setting', component: SettingComponent },
		{ name: 'vli_support', component: SupportComponent },
		{ name: 'vli_tutorial', component: TutorialComponent },

		{ name: 'vli_anl-attentions', component: AnlAttentionsComponent },
		{ name: 'vli_anl-satisfaction', component: AnlSatisfactionComponent },
		{ name: 'vli_anl-resolvability', component: AnlResolvabilityComponent },

		{ name: 'vli_conversations', component: ConversationsComponent },
		{ name: 'vli_contact', component: ContactComponent },
		{ name: 'vli_evaluations', component: EvaluationsComponent },

		{ name: 'vli_contact-center', component: ContactCenterComponent },

		{ name: 'vli_user', component: UserComponent },
		{ name: 'vli_group', component: GroupComponent },
		{ name: 'vli_types_att', component: TypesAttComponent },
		{ name: 'vli_sub_att', component: SubAttComponent },
		{ name: 'vli_app', component: AppComponent },
		{ name: 'vli_states', component: StatesComponent },
		{ name: 'vli_rols', component: RolsComponent },
		{ name: 'vli_history', component: HistoryComponent },

		{ name: 'vli_account', component: AccountComponent },
		{ name: 'vli_invoices', component: InvoicesComponent },
		{ name: 'vli_payments', component: PaymentsComponent },
		{ name: 'vli_pricing', component: PricingComponent },

		{ name: 'vli_back', component: ArrowBackComponent },

		{ name: 'vli_active', component: ActiveComponent },
		{ name: 'vli_inactive', component: InactiveComponent },
		{ name: 'vli_trash', component: TrashComponent },
		{ name: 'vli_edit', component: EditComponent },
		{ name: 'vli_detail', component: DetailComponent },

		{ name: 'vli_iconSelect', component: IconSelectComponent },

		{ name: 'vli_toastSuccess', component: ToastSuccessComponent },
		{ name: 'vli_toastError', component: ToastErrorComponent },
		{ name: 'vli_toastWarning', component: ToastWarningComponent },
	];
	public currentComponent: null | any = null;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['name']) this.setCurrentComponent();
	}

	setCurrentComponent() {
		const match = this.iconsRef.find((icon) => icon.name === this.name);
		if (match) {
			this.currentComponent = match.component;
			this.isCustom = true;
		} else {
			this.currentComponent = null;
			this.isCustom = false;
		}
	}
}
