<div [ngClass]="['hl-vl-fgroup']">
	<div [ngClass]="['hl-vl-fgroup__header']" *ngIf="label && type != 'status-muni-vzla'">
		<p [ngClass]="['hl-vl-fgroup__header__label']">
			{{ label }}
			<span [ngClass]="['hl-vl-fgroup__header__label__required']" *ngIf="required">*</span>
		</p>
	</div>
	<div>
		<ng-container *ngIf="type === 'select'">
			<hk-vl-select
				[value]="this.value"
				[options]="selectOptions"
				(onChange)="onChange($event)"
				[colorbgHoverSelect]="colorbgHoverSelect"
				[colorTextHoverSelect]="colorTextHoverSelect"
			></hk-vl-select>
		</ng-container>
		<ng-container *ngIf="type === 'yes-no'">
			<div [ngClass]="['hk-vl-box-horizontal']">
				<button [ngClass]="['hl-vl-fcontrol-radio']" (click)="this.value = 'si'; onChange(this.value)">
					<span
						[ngClass]="['hl-vl-fcontrol-radio__box', this.value === 'si' ? 'selected' : '']"
						[ngStyle]="{ '--pink-2': colorCheck }"
					></span>
					<span [ngClass]="['hl-vl-fcontrol-radio__label']">Si</span>
				</button>
				<button [ngClass]="['hl-vl-fcontrol-radio']" (click)="this.value = 'no'; onChange(this.value)">
					<span
						[ngClass]="['hl-vl-fcontrol-radio__box', this.value === 'no' ? 'selected' : '']"
						[ngStyle]="{ '--pink-2': colorCheck }"
					></span>
					<span [ngClass]="['hl-vl-fcontrol-radio__label']">No</span>
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="type === 'regex'">
			<input
				type="text"
				hkFormTrim
				[ngClass]="['hl-vl-fcontrol-input']"
				[(ngModel)]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				[placeholder]="placeholder"
			/>
		</ng-container>
		<ng-container *ngIf="type === 'download'">
			<a [ngClass]="['hl-vl-fcontrol-download']" *ngIf="download" [href]="storageUrl + download" target="_blank" download>{{ label }}</a>

			<input type="file" [multiple]="false" [accept]="accept" (change)="uploadFile($event)" #inputFile />
			<div [ngClass]="['hl-vl-fcontrol-upload']" *ngIf="!this.fileTemp?.name && !this.value">
				<button [ngClass]="['hl-vl-fcontrol-upload__boxUpload']" type="button" (click)="inputFile.click()">
					<svg [ngClass]="['hl-vl-fcontrol-upload__boxUpload__icon']" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M4.81303 8C4.69939 7.95312 4.63405 7.83825 4.65464 7.72168V3.54034H3.21877C2.98933 3.51851 2.9369 3.42109 3.07789 3.25172C3.91392 2.24371 4.75125 1.23813 5.59411 0.239058C5.86922 -0.0854316 5.99673 -0.0738884 6.28926 0.239058C7.15618 1.20609 8.02224 2.17356 8.8872 3.14156C8.93522 3.2125 8.97323 3.28935 9 3.36996C8.91762 3.4036 8.83104 3.42686 8.74229 3.43921C8.31825 3.4469 7.89418 3.43921 7.47004 3.43921H7.31175V7.72551C7.33037 7.84003 7.26101 7.95096 7.14671 7.9897H4.81302L4.81303 8Z"
							fill="currentColor"
						/>
						<path
							d="M0.00730957 8.00008H1.66333V10.5105H10.332V8.00107H11.9928C11.9928 8.03976 12 8.0773 12 8.11159V11.1867C12 11.6928 11.6532 11.9999 11.0868 11.9999H0.902462C0.456064 12.0043 0.141665 11.8032 0.0336011 11.4519C0.0105826 11.3737 -0.000718878 11.2931 3.54047e-05 11.2121V8.09395C3.54047e-05 8.06628 0.000110881 8.03769 0.00730957 8.00008Z"
							fill="currentColor"
						/>
					</svg>

					<span [ngClass]="['hl-vl-fcontrol-upload__boxUpload__text']"> Adjuntar archivo </span>
				</button>
				<p [ngClass]="['hl-vl-fcontrol-upload__msgAccept']">Formato {{ format }}. Máximo 25mb</p>
			</div>

			<div [ngClass]="['hl-vl-fcontrol-btnRefUpload']" *ngIf="this.fileTemp?.name || this.value">
				<p [ngClass]="['hl-vl-fcontrol-btnRefUpload__text']">
					{{ this.fileTemp.name ?? this.fileName }}
				</p>

				<button [ngClass]="['hl-vl-fcontrol-btnRefUpload__btnDelete']" (click)="clearRefFile()">
					<svg
						[ngClass]="['hl-vl-fcontrol-btnRefUpload__btnDelete__icon']"
						viewBox="0 0 12 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_980_9389)">
							<path
								d="M8.03184 4.1582C8.03184 4.10664 7.98965 4.06445 7.93809 4.06445L7.16465 4.06797L5.9998 5.45664L4.83613 4.06914L4.06152 4.06563C4.00996 4.06563 3.96777 4.10664 3.96777 4.15938C3.96777 4.18164 3.97598 4.20273 3.99004 4.22031L5.51465 6.03672L3.99004 7.85195C3.97588 7.86913 3.96802 7.89063 3.96777 7.91289C3.96777 7.96445 4.00996 8.00664 4.06152 8.00664L4.83613 8.00313L5.9998 6.61445L7.16348 8.00195L7.93691 8.00547C7.98848 8.00547 8.03066 7.96445 8.03066 7.91172C8.03066 7.88945 8.02246 7.86836 8.0084 7.85078L6.48613 6.03555L8.01074 4.21914C8.0248 4.20273 8.03184 4.18047 8.03184 4.1582Z"
								fill="currentColor"
							/>
							<path
								d="M6 0.761719C3.10078 0.761719 0.75 3.1125 0.75 6.01172C0.75 8.91094 3.10078 11.2617 6 11.2617C8.89922 11.2617 11.25 8.91094 11.25 6.01172C11.25 3.1125 8.89922 0.761719 6 0.761719ZM6 10.3711C3.59297 10.3711 1.64062 8.41875 1.64062 6.01172C1.64062 3.60469 3.59297 1.65234 6 1.65234C8.40703 1.65234 10.3594 3.60469 10.3594 6.01172C10.3594 8.41875 8.40703 10.3711 6 10.3711Z"
								fill="currentColor"
							/>
						</g>
						<defs>
							<clipPath id="clip0_980_9389">
								<rect width="12" height="12" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="type === 'upload'">
			<input type="file" [multiple]="false" [accept]="accept" (change)="uploadFile($event)" #inputFile />
			<div [ngClass]="['hl-vl-fcontrol-upload']" *ngIf="!this.fileTemp?.name && !this.value">
				<button [ngClass]="['hl-vl-fcontrol-upload__boxUpload']" type="button" (click)="inputFile.click()">
					<svg [ngClass]="['hl-vl-fcontrol-upload__boxUpload__icon']" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M4.81303 8C4.69939 7.95312 4.63405 7.83825 4.65464 7.72168V3.54034H3.21877C2.98933 3.51851 2.9369 3.42109 3.07789 3.25172C3.91392 2.24371 4.75125 1.23813 5.59411 0.239058C5.86922 -0.0854316 5.99673 -0.0738884 6.28926 0.239058C7.15618 1.20609 8.02224 2.17356 8.8872 3.14156C8.93522 3.2125 8.97323 3.28935 9 3.36996C8.91762 3.4036 8.83104 3.42686 8.74229 3.43921C8.31825 3.4469 7.89418 3.43921 7.47004 3.43921H7.31175V7.72551C7.33037 7.84003 7.26101 7.95096 7.14671 7.9897H4.81302L4.81303 8Z"
							fill="currentColor"
						/>
						<path
							d="M0.00730957 8.00008H1.66333V10.5105H10.332V8.00107H11.9928C11.9928 8.03976 12 8.0773 12 8.11159V11.1867C12 11.6928 11.6532 11.9999 11.0868 11.9999H0.902462C0.456064 12.0043 0.141665 11.8032 0.0336011 11.4519C0.0105826 11.3737 -0.000718878 11.2931 3.54047e-05 11.2121V8.09395C3.54047e-05 8.06628 0.000110881 8.03769 0.00730957 8.00008Z"
							fill="currentColor"
						/>
					</svg>

					<span [ngClass]="['hl-vl-fcontrol-upload__boxUpload__text']"> Adjuntar archivo </span>
				</button>
				<p [ngClass]="['hl-vl-fcontrol-upload__msgAccept']">Formato {{ format }}. Máximo 25mb</p>
			</div>

			<div [ngClass]="['hl-vl-fcontrol-btnRefUpload']" *ngIf="this.fileTemp?.name || this.value">
				<p [ngClass]="['hl-vl-fcontrol-btnRefUpload__text']">
					{{ this.fileTemp.name ?? this.fileName }}
				</p>

				<button [ngClass]="['hl-vl-fcontrol-btnRefUpload__btnDelete']" (click)="clearRefFile()">
					<svg
						[ngClass]="['hl-vl-fcontrol-btnRefUpload__btnDelete__icon']"
						viewBox="0 0 12 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_980_9389)">
							<path
								d="M8.03184 4.1582C8.03184 4.10664 7.98965 4.06445 7.93809 4.06445L7.16465 4.06797L5.9998 5.45664L4.83613 4.06914L4.06152 4.06563C4.00996 4.06563 3.96777 4.10664 3.96777 4.15938C3.96777 4.18164 3.97598 4.20273 3.99004 4.22031L5.51465 6.03672L3.99004 7.85195C3.97588 7.86913 3.96802 7.89063 3.96777 7.91289C3.96777 7.96445 4.00996 8.00664 4.06152 8.00664L4.83613 8.00313L5.9998 6.61445L7.16348 8.00195L7.93691 8.00547C7.98848 8.00547 8.03066 7.96445 8.03066 7.91172C8.03066 7.88945 8.02246 7.86836 8.0084 7.85078L6.48613 6.03555L8.01074 4.21914C8.0248 4.20273 8.03184 4.18047 8.03184 4.1582Z"
								fill="currentColor"
							/>
							<path
								d="M6 0.761719C3.10078 0.761719 0.75 3.1125 0.75 6.01172C0.75 8.91094 3.10078 11.2617 6 11.2617C8.89922 11.2617 11.25 8.91094 11.25 6.01172C11.25 3.1125 8.89922 0.761719 6 0.761719ZM6 10.3711C3.59297 10.3711 1.64062 8.41875 1.64062 6.01172C1.64062 3.60469 3.59297 1.65234 6 1.65234C8.40703 1.65234 10.3594 3.60469 10.3594 6.01172C10.3594 8.41875 8.40703 10.3711 6 10.3711Z"
								fill="currentColor"
							/>
						</g>
						<defs>
							<clipPath id="clip0_980_9389">
								<rect width="12" height="12" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="type === 'textarea'">
			<textarea
				[ngClass]="['hl-vl-fcontrol-textarea']"
				[(ngModel)]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				[placeholder]="placeholder"
				[minlength]="minlength"
				[maxlength]="maxlength"
				hkFormTrim
			></textarea>
		</ng-container>
		<ng-container *ngIf="type === 'text' && entryInput === 'allChart'">
			<input
				[ngClass]="['hl-vl-fcontrol-input']"
				[type]="mask ? 'password' : 'text'"
				[(ngModel)]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				[placeholder]="placeholder"
				[minlength]="minlength"
				[maxlength]="maxlength"
				hkFormTrim
			/>
		</ng-container>
		<ng-container *ngIf="type === 'text' && entryInput === 'onlyNumber'">
			<input
				[ngClass]="['hl-vl-fcontrol-input']"
				[type]="mask ? 'password' : 'text'"
				[(ngModel)]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				[placeholder]="placeholder"
				[minlength]="minlength"
				[maxlength]="maxlength"
				hkFormTrim
				oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
			/>
		</ng-container>
		<ng-container *ngIf="type === 'text' && entryInput === 'onlyText'">
			<input
				[ngClass]="['hl-vl-fcontrol-input']"
				[type]="mask ? 'password' : 'text'"
				[(ngModel)]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				[placeholder]="placeholder"
				[minlength]="minlength"
				[maxlength]="maxlength"
				hkFormTrim
				oninput="this.value = this.value.replace(/[^a-zA-Z]/g, '').replace(/(\..*)\./g, '$1');"
			/>
		</ng-container>
		<ng-container *ngIf="type === 'number'">
			<input
				[ngClass]="['hl-vl-fcontrol-input']"
				[type]="mask ? 'password' : 'text'"
				[(ngModel)]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				[minlength]="min ? min.toString().length : null"
				[maxlength]="max ? max.toString().length : null"
				[min]="min ? min : undefined"
				[max]="max ? max : undefined"
				hkFormTrim
				oninput="this.value = this.value.replace(/[^0-9,.]/g, '').replace(/,/,'.').replace(/^(\d*\.\d{0,2}).*$/, '$1')"
				[placeholder]="placeholder"
			/>
		</ng-container>
		<ng-container *ngIf="type === 'email'">
			<input
				[ngClass]="['hl-vl-fcontrol-input']"
				type="email"
				[(ngModel)]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				[placeholder]="placeholder"
				hkFormTrim
			/>
		</ng-container>
		<ng-container *ngIf="type === 'phone'">
			<input
				[ngClass]="['hl-vl-fcontrol-input']"
				type="tel"
				minlength="10"
				maxlength="11"
				[(ngModel)]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
				[placeholder]="placeholder"
				hkFormTrim
			/>
		</ng-container>
		<ng-container *ngIf="type === 'date'">
			<input
				[ngClass]="['hl-vl-fcontrol-input']"
				[min]="minDate"
				[max]="maxDate"
				type="date"
				[(ngModel)]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				hkFormTrim
			/>
		</ng-container>
		<ng-container *ngIf="type === 'range-date'">
			<div [ngClass]="['hk-vl-box-horizontal']">
				<div [ngClass]="['hk-vl-box-vertical']">
					<label [ngClass]="['middleLabel']">Desde:</label>
					<input
						[ngClass]="['hl-vl-fcontrol-input']"
						[min]="minDateStart"
						[max]="maxDateStart"
						type="date"
						#inputStartDate
						(change)="this.inputRangeDateOnChange('start', inputStartDate)"
						hkFormTrim
					/>
				</div>
				<div [ngClass]="['hk-vl-box-vertical']">
					<label [ngClass]="['middleLabel']">Hasta:</label>
					<input
						[ngClass]="['hl-vl-fcontrol-input']"
						[min]="minDateEnd"
						[max]="maxDateEnd"
						type="date"
						#inputEndDate
						(change)="this.inputRangeDateOnChange('end', inputEndDate)"
						hkFormTrim
					/>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="type === 'rif'">
			<div [ngClass]="['hk-vl-box-horizontal--rif']">
				<hk-vl-select
					customMsgNotValue=" "
					[value]="this.value"
					(onChange)="this.changeMultiValue(0, $event); this.onTouched()"
					[options]="[
						{ label: 'J', value: 'J' },
						{ label: 'V', value: 'V' },
						{ label: 'E', value: 'E' },
						{ label: 'P', value: 'P' },
						{ label: 'G', value: 'G' }
					]"
				></hk-vl-select>
				-
				<input
					#inputRIF1
					[ngClass]="['hl-vl-fcontrol-input']"
					type="text"
					minlength="8"
					maxlength="8"
					placeholder="00000000"
					(input)="this.changeMultiValue(1, inputRIF1.value); this.onTouched()"
					oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
					hkFormTrim
				/>
				-
				<input
					#inputRIF2
					[ngClass]="['hl-vl-fcontrol-input']"
					type="text"
					minlength="1"
					maxlength="1"
					placeholder="0"
					(input)="this.changeMultiValue(2, inputRIF2.value); this.onTouched()"
					oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
					hkFormTrim
				/>
			</div>
		</ng-container>
		<ng-container *ngIf="type === 'ci'">
			<div [ngClass]="['hk-vl-box-horizontal--ci']">
				<hk-vl-select
					customMsgNotValue=" "
					[value]="this.value"
					(onChange)="this.changeMultiValue(0, $event); this.onTouched()"
					[options]="[
						{ label: 'V', value: 'V' },
						{ label: 'E', value: 'E' }
					]"
				></hk-vl-select>

				-
				<input
					#inputCI
					[ngClass]="['hl-vl-fcontrol-input']"
					(input)="this.changeMultiValue(1, inputCI.value); this.onTouched()"
					type="text"
					minlength="6"
					maxlength="8"
					placeholder="00000000"
					oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
					hkFormTrim
				/>
			</div>
		</ng-container>
		<ng-container *ngIf="type === 'status-muni-vzla'">
			<div style="display: flex; flex-direction: column; gap: 12px">
				<div style="display: flex; flex-direction: column; gap: 12px">
					<label [ngClass]="['hl-vl-fgroup__header__label']"
						>Estado:

						<span [ngClass]="['hl-vl-fgroup__header__label__required']" *ngIf="required">*</span>
					</label>
					<hk-vl-select [options]="statesOfVnzla" (onChange)="this.changeMultiValue(0, $event)"></hk-vl-select>
				</div>
				<div style="display: flex; flex-direction: column; gap: 12px">
					<label [ngClass]="['hl-vl-fgroup__header__label']"
						>Municipio:

						<span [ngClass]="['hl-vl-fgroup__header__label__required']" *ngIf="required">*</span>
					</label>
					<hk-vl-select [options]="muniOfVnzla[multiInput[0] ?? ''] ?? []" (onChange)="this.changeMultiValue(1, $event)"></hk-vl-select>
				</div>
			</div>
		</ng-container>
	</div>
	<div [ngClass]="['hl-vl-fgroup__errors']">
		<p
			[ngClass]="['hl-vl-fgroup__errors__msg']"
			*ngIf="fControl?.errors && keys(fControl.errors ?? {}).length > 0 && (fControl?.dirty || fControl?.touched) && customMsgValidation"
		>
			{{ customMsgValidation }}
		</p>
		<p
			[ngClass]="['hl-vl-fgroup__errors__msg']"
			*ngIf="fControl?.errors && keys(fControl?.errors ?? {}).length > 0 && (fControl?.dirty || fControl?.touched) && !customMsgValidation"
		>
			{{ errorFormMsg[keys(fControl.errors ?? {})[0]] ?? errorFormMsg['default'] }}
		</p>
	</div>
</div>
