import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError, Observable, catchError } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ApiService<T> {
	constructor(private http: HttpClient) {}

	private formatErrors(error: any) {
		return throwError(() => error.error);
	}

	get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http
			.get<CustomResponse<T>>(`${environment.apiServer}${path}`, {
				params,
			})
			.pipe(catchError(this.formatErrors));
	}

	put(path: string, body: object = {}): Observable<any> {
		return this.http.put<CustomResponse<T>>(`${environment.apiServer}${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors));
	}

	post(path: string, body: object = {}): Observable<any> {
		return this.http.post<CustomResponse<T>>(`${environment.apiServer}${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors));
	}

	delete(path: string): Observable<any> {
		return this.http.delete<CustomResponse<T>>(`${environment.apiServer}${path}`).pipe(catchError(this.formatErrors));
	}
}
