import { Component, OnInit, HostListener, ViewChild, ViewEncapsulation, Input, SimpleChanges, AfterViewInit, inject } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ApiService } from '@services/backend/api.service';
import { WebSocketService } from '@services/backend/web-socket.service';
import { DimensionsScreenService } from '@services/components/dimensions-screen.service';
import { AuthService } from '@services/storage/auth.service';
import { LocalstorageService } from '@services/storage/localstorage.service';
import { lastValueFrom, tap } from 'rxjs';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, AfterViewInit {
	/**
	
	window.addEventListener('offline', () => {
	console.log('La conexión a Internet se ha perdido.');
	});

	// Detectar cuando la conexión vuelve
	window.addEventListener('online', () => {
	console.log('La conexión a Internet ha sido restaurada.');
	});

	// Verificar el estado actual de la conexión
	if (!navigator.onLine) {
	console.log('Actualmente sin conexión a Internet.');
	}
		 
	 */

	// @HostListener('window:beforeunload', ['$event'])
	// onBeforeunload(event: any) {
	// 	console.log('Acción antes de cerrar la ventana');
	// }
	// @HostListener('window:offline', ['$event'])
	// onoffline(event: any) {
	// 	console.log('Se fue el internet');
	// }
	// @HostListener('window:online', ['$event'])
	// ononline(event: any) {
	// 	console.log('Volvio el internet');
	// }

	@HostListener('window:keydown', ['$event'])
	onKeydown(btn: any) {
		if (btn.key === 'Escape' && this.subMenuActive) {
			this.forceCloseMenuMobile(btn);
		}
	}
	_user: any = undefined;
	private timeoutId: any;

	public subMenuActive: boolean = false;
	public screenWidth!: number;
	constructor(
		private _dimensionsScreen: DimensionsScreenService,
		private _authService: AuthService,
		private _api: ApiService,
		private _ws: WebSocketService
	) {
		this._dimensionsScreen.change
			.pipe(
				tap(({ width }) => {
					this.screenWidth = width;
					if (width >= 1024 && this.subMenuActive) {
						this.forceCloseMenuMobile({});
					}
				})
			)
			.subscribe();
	}
	ngAfterViewInit(): void {
		const old = document.querySelector('videolink-wid');
		if (old) old.remove();
		if (!this._authService.getToken()) return;

		const dataVideolink = document.createElement('videolink-wid');
		const tokenSupport = environment.tokenAppSupport;

		// const dataVideolink = document.querySelector('videolink-wid');
		const userInfo = this._authService.getInfo();

		const data = {
			clientId: userInfo?.email,
			name: `${userInfo?.firstname} ${userInfo?.lastname}`,
			phone: `${userInfo?.phoneCode ?? '+58'}${userInfo?.phone ?? '0000000000'}`,
			email: `${userInfo?.email}`,
			data: {
				Compañia: userInfo.company_name,
			},
		};

		dataVideolink.setAttribute('token', tokenSupport);
		dataVideolink.style.zIndex = '15000000';
		dataVideolink.setAttribute('data', JSON.stringify(data));
		//@ts-ignore
		dataVideolink.style.display = 'flex';
		document.body.append(dataVideolink);

		// if (dataVideolink) {
		// }
	}

	onRefreshToken = async () => {
		try {
			const result = await lastValueFrom(this._api.post(`/auth/refresh-token`));
			return result.token;
		} catch (err) {
			console.log(err);
		}
	};

	set user(value: any) {
		this._user = value;
		this.checkTokenExpiration();
	}

	get user(): any {
		return this._user;
	}

	private checkTokenExpiration(): void {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId); // Cancelar timeout anterior si existe
		}

		const currentTime = new Date().getTime();
		const expirationTime = new Date(this.user.exp * 1000).getTime();
		const timeRemaining = expirationTime - currentTime;
		const refreshTime = timeRemaining - 1800000;

		this.timeoutId = setTimeout(async () => {
			const token: string = await this.onRefreshToken();
			localStorage.setItem('tokenAuth', token);
			this.user = this._authService.getInfo();
			this._ws.closeSocket();
			this._ws.init();
		}, refreshTime);
	}

	ngOnInit(): void {
		this.user = this._authService.getInfo();

		if (this.timeoutId) {
			clearTimeout(this.timeoutId); // Cancelar timeout anterior si existe
		}
		this.checkTokenExpiration();
	}

	toggleMenuMobile(event: any) {
		this.subMenuActive = !this.subMenuActive;
	}
	forceCloseMenuMobile(event: any) {
		this.subMenuActive = false;
	}
}
