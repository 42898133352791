import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { lastValueFrom, tap, BehaviorSubject, retry, map } from 'rxjs';
import { environment } from '@environments/environment';
import { ApiService } from '@services_shared/global/api.service';
import { AuthService } from '@services_shared/global/auth.service';
import { LoginService } from '@services_shared/global/login.service';

@Injectable({
	providedIn: 'root',
})
export class CrudResourceGenerator<T = any, K = any> {
	primaryRoute: string = '';
	fileNameExport: string = '';

	tapCustom: {
		createResource?: (response: CustomResponse<T>) => any | Promise<any>;
		getResources?: (response: CustomResponse<T>) => any | Promise<any>;
		getDatalist?: (response: CustomResponse<T>) => any | Promise<any>;
		getDatalistOnlyRead?: (response: CustomResponse<T>) => any | Promise<any>;
		getOneResource?: (response: CustomResponse<T>) => any | Promise<any>;
		updateResource?: (response: CustomResponse<T>) => any | Promise<any>;
		deleteResource?: (response: CustomResponse<T>) => any | Promise<any>;
	} = {};

	dataList: optionSelectVl<K>[] = [];
	$datalist: BehaviorSubject<optionSelectVl<K>[]> = new BehaviorSubject<optionSelectVl<K>[]>([]);
	dataListOnlyRead: optionSelectVl<K>[] = [];
	$dataListOnlyRead: BehaviorSubject<optionSelectVl<K>[]> = new BehaviorSubject<optionSelectVl<K>[]>([]);

	dataSource: K[] = [];
	sortDataListKey: string = 'name';
	sortAscending: boolean = true;

	private _queryHttp: HttpParams = new HttpParams();

	constructor(
		private _apiSrc: ApiService<T> = inject(ApiService),
		private _authSrc: AuthService = inject(AuthService),
		private _loginSrc: LoginService = inject(LoginService),
		private _httpSrc: HttpClient = inject(HttpClient)
	) {}

	get apiSrc() {
		return this._apiSrc;
	}

	get authSrc() {
		return this._authSrc;
	}
	get queryHttp() {
		return this._queryHttp;
	}
	get httpSrc() {
		return this._httpSrc;
	}

	set queryHttp(query: any) {
		this._queryHttp = new HttpParams({ fromObject: query });
	}

	createResource({ body = {} }) {
		return this.apiSrc.post(this.primaryRoute, body).pipe(
			tap(async (response) => {
				this.tapCustom?.createResource && (await this.tapCustom.createResource(response));
			})
		);
	}

	getResources({ httpParams = this.queryHttp } = {}) {
		return this.apiSrc.get(this.primaryRoute, httpParams).pipe(
			tap(async (response) => {
				this.tapCustom?.getResources && (await this.tapCustom.getResources(response));
			})
		);
	}

	getOneResource(id: string, httpParams = this.queryHttp) {
		return this.apiSrc.get(`${this.primaryRoute}/${id}`, httpParams).pipe(
			tap(async (response) => {
				this.tapCustom?.getOneResource && (await this.tapCustom.getOneResource(response));
			})
		);
	}

	getDatalist({ page = 1, limit = 200, search = '', query = {} } = {}) {
		const httpParams = new HttpParams({
			fromObject: {
				page,
				limit,
				search,
				sortBy: this.sortDataListKey,
				isAscending: this.sortAscending,
				...query,
			},
		});

		return this.apiSrc.get(this.primaryRoute, httpParams).pipe(
			tap(async (response) => {
				this.tapCustom?.getDatalist && (await this.tapCustom.getDatalist(response));
			})
		);
	}
	getDatalistOnlyRead({ page = 1, limit = 200, search = '', query = {} } = {}) {
		const httpParams = new HttpParams({
			fromObject: {
				page,
				limit,
				search,
				isReadOnly: true,
				sortBy: this.sortDataListKey,
				isAscending: this.sortAscending,
				...query,
			},
		});

		return this.apiSrc.get(this.primaryRoute, httpParams).pipe(
			tap(async (response) => {
				this.tapCustom?.getDatalistOnlyRead && (await this.tapCustom.getDatalistOnlyRead(response));
			})
		);
	}

	updateResource(id: string, body: object = {}) {
		return this.apiSrc.put(`${this.primaryRoute}/${id}`, body).pipe(
			tap(async (response) => {
				this.tapCustom?.updateResource && (await this.tapCustom.updateResource(response));
			})
		);
	}

	deleteResource(id: string) {
		return this.apiSrc.delete(`${this.primaryRoute}/${id}`).pipe(
			tap(async (response) => {
				this.tapCustom?.deleteResource && (await this.tapCustom.deleteResource(response));
			})
		);
	}
	actionResource(action: string, body = {}) {
		return this.apiSrc.put(`${this.primaryRoute}/action/${action}`, body);
	}

	async downloadListResource({ httpParams = this.queryHttp }) {
		try {
			const {
				data: { url = '' },
			} = await lastValueFrom(this.apiSrc.get(`${this.primaryRoute}/download`, httpParams));
			if (url) window.open(environment.apiServer + url);
		} catch (error) {
			if (environment.production) console.log(error);
		}
	}

	async refreshToken() {
		await this._loginSrc.refreshToken(false);
	}

	blobToSaveAs({ fileName, blob }: { fileName: string; blob: Blob }) {
		try {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			if (link.download !== undefined) {
				// feature detection
				link.setAttribute('href', url);
				link.setAttribute('download', fileName);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (e) {
			console.error('BlobToSaveAs error', e);
		}
	}

	exportResource() {
		return this.httpSrc.get(`${environment.apiServer}${this.primaryRoute}/export`, { responseType: 'blob', observe: 'response' }).pipe(
			map((res: HttpResponse<Blob>) => {
				this.blobToSaveAs({
					fileName: `${this.fileNameExport}`,
					blob: res.body as Blob,
				});

				return res;
			})
		);
	}
}
