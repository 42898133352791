import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-forms-contact-btn',
  templateUrl: './forms-contact-btn.component.html',
  styleUrls: ['./forms-contact-btn.component.scss']
})
export class FormsContactBtnComponent implements OnInit {
  @Input('disabled') disabled: boolean = false
  @Input('color') color: string = ''
  @Input('background') background: string = ''
  constructor() {}
  ngOnInit(): void {
    
  }
}