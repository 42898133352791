import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iCC } from '@interfaces/iCC';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ContactCenterService {
	public ccInfoData: BehaviorSubject<any> = new BehaviorSubject({
		banner: 'https://cdn.dev.videolink.app/c/639cd688da5dca0441c18d8a',
		hasForm: true,
		principalColor: '#1B0845',
		buttonColor: '#EA345F',
		colorTextBanner: '#FFFFFF',
		welcomeTextColor: '#FFFFFF',
		iconColor: '#2C0D71',
		buttonAttentionsColor: '#1B0845',
		buttonTitle: 'Seleccionar',
		title: 'Puedes solicitar una atención con uno de nuestros agentes.',
		description: 'Para agendar una atención, seleccione uno de los siguientes tipos de atenciones.',
		attentionsForms: [],
	});
	setInfoCC(value: iCC) {
		this.ccInfoData.next(value);
		// console.log(this.ccInfoData.value, ' tengo datos?');
	}
	constructor(private http: HttpClient) {}

	fileUpload(
		path: string = '/easy/banner',
		file: File,
		field: string = 'image',
		company: string,
		nameCompany: string = 'companyId'
	): Observable<any> {
		const formData = new FormData();
		formData.append(field, file);
		formData.append('companyId', company);
		console.log(formData);

		return this.http
			.post(`${environment.storage}${path}`, formData, {
				reportProgress: true,
				observe: 'events' as 'body',
				headers: { file: 'true' },
			})
			.pipe(catchError(this.formatErrors));
	}

	private formatErrors(error: any) {
		return throwError(error.error);
	}
}
