<header class="header-content" [style]="'text-align:' + titleAlign">
	<h1 class="title">
		{{ title }}
	</h1>
	<p
		class="description"
		Copy
		[ngStyle]="{
			'text-align': descriptionAlign,
		}"
		*ngIf="description.length > 1"
	>
		<!-- 'max-width': descriptionAlign === 'center' ? 'none' : '700px' -->
		{{ description }}
	</p>
</header>
