import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { EventEmitter, Injectable, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LocalstorageService } from '@services/storage/localstorage.service';
import { ToastService } from '@services/backend/toast.service';
import { LoadingService } from './loading.service';
import { ApiService } from '@services/backend/api.service';

@Injectable({
	providedIn: 'root',
})
export class TourService {
	public isActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!!this._localStorageSrc.get('tourActive'));
	public isModalActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public preferencesUser: BehaviorSubject<any> = new BehaviorSubject<any>({});
	public tourActive: BehaviorSubject<any> = new BehaviorSubject<any>(
		!!this._localStorageSrc.get('progressTour')
			? JSON.parse(this._localStorageSrc.get('progressTour') as string)
			: { module: '', step: null }
	);
	public actions: EventEmitter<any> = new EventEmitter();

	public dataTour = [
		{
			module: 'attention',
			steps: [
				{
					title: 'Módulo de atención',
					text: 'Esta sección permite gestionar las atenciones, usuarios, contactos, grupos, tipos de atenciones y aplicaciones que se ubican en el submenú izquierdo.',
					path: '/dashboard/attentions',
					clipPath: {
						cut: 1,
						resourcesID: ['#menuLateralAttentions', '#subMenuAttentions'],
					},
				},
				{
					title: 'Listado de atenciones',
					text: 'Observarás un listado con todas las atenciones finalizadas, estás se encuentran organizadas por fecha y podrás realizar búsquedas utilizando filtros personalizados.',
					path: '/dashboard/attentions',
					clipPath: {
						cut: 3,
						indexAccordeonActive: 0,
						resourcesID: ['#triggerPathAttencionsList', '#attentionsTable'],
					},
				},
				{
					title: 'Listado de atenciones',
					text: 'Al hacer click en el icono del menú podrás visualizar el detalle de la atención seleccionada.',
					path: '/dashboard/attentions',
					clipPath: {
						cut: 4,
						resourcesID: ['.btnMenuTable'],
					},
				},

				{
					title: 'Detalle de atención',
					text: 'En esta vista podrás visualizar la información general de la atención, los datos del cliente, los mensajes, los archivos enviados y la evaluación de la atención. En caso de que la atención esté en tiempo real está información cambiará progresivamente.',
					path: '/dashboard/attentions/details/c5e90b103d4fffadf51a80ce',
					clipPath: {
						cut: 5,
						resourcesID: ['#conversationDetails'],
					},
				},
				{
					title: 'Atenciones: Tiempo real',
					text: 'En la tabla de tiempo real verás todas las atenciones que se encuentran en vivo. Podrás realizar las acciones de “Terminar la atención o Transferir”.',
					path: '/dashboard/attentions/real-time',
					clipPath: {
						cut: 7,
						indexAccordeonActive: 0,
						resourcesID: ['#triggerPathAttencionsListCurrent', '#attentionsTable'],
					},
				},
				{
					title: 'Atenciones: En cola',
					text: 'La tabla contiene la lista de atenciones que aún se encuentran en espera, no existen acciones en esta tabla.',
					path: '/dashboard/attentions/queued',
					clipPath: {
						cut: 7,
						indexAccordeonActive: 0,
						resourcesID: ['#triggerPathAttencionsListQueue', '#attentionsTable'],
					},
				},
				{
					title: 'Usuarios',
					text: 'En esta sección observarás una tabla donde podrás eliminar, editar, ver detalle, activar o desactivar a un usuario.',
					path: '/dashboard/attentions/users',
					clipPath: {
						cut: 7,
						indexAccordeonActive: null,
						resourcesID: ['#triggerPathUsers', '#usersTable'],
					},
				},
				{
					title: 'Usuarios',
					text: 'Para crear un nuevo usuario, debes hacer click en el botón superior izquierdo.',
					path: '/dashboard/attentions/users',
					clipPath: {
						cut: 4,
						indexAccordeonActive: null,
						resourcesID: ['#btnNewUser'],
					},
				},
				{
					title: 'Nuevo usuario',
					text: 'Luego de dar click al botón “Nuevo Usuario” se carga un formulario, podrás asignarle un rol, uno o varios grupos y tipos de atención. Es importante asignarle un grupo y un tipo de atención.',
					path: '/dashboard/attentions/users/create',
					clipPath: {
						cut: 5,
						indexAccordeonActive: null,
						resourcesID: ['#userForm'],
					},
				},
				{
					title: 'Grupos',
					text: 'En esta sección te presentamos la tabla donde contiene el listado de los grupos, en esta podrás eliminar, editar, ver detalle, activar o inactivar un grupo.',
					path: '/dashboard/attentions/groups',
					clipPath: {
						cut: 7,
						indexAccordeonActive: null,
						resourcesID: ['#triggerPathGroups', '#groupTable'],
					},
				},
				{
					title: 'Grupos',
					text: 'Cada grupo está asociado a tu Contact Center, podrás acceder haciendo click en el icono de copiar URL.',
					path: '/dashboard/attentions/groups',
					clipPath: {
						cut: 4,
						indexAccordeonActive: null,
						resourcesID: ['button.contentBtnActions__icon'],
					},
				},
				{
					title: 'Grupos',
					text: 'O puedes acceder directamente haciendo click a ese botón.',
					path: '/dashboard/attentions/groups',
					clipPath: {
						cut: 4,
						indexAccordeonActive: null,
						resourcesID: ['a.contentBtnActions__icon'],
					},
				},
				{
					title: 'Grupos',
					text: 'Para crear un nuevo grupo, debes hacer click en el botón superior izquierdo.',
					path: '/dashboard/attentions/groups',
					clipPath: {
						cut: 4,
						indexAccordeonActive: null,
						resourcesID: ['#newTypeAttentionsBtn'],
					},
				},
				{
					title: 'Nuevo Grupo',
					text: 'Luego de dar click al botón “Nuevo Grupo” se carga un formulario, debes tener en cuenta que para crear un grupo es importante asociarlo a un tipo de atención.',
					path: '/dashboard/attentions/groups/create',
					clipPath: {
						cut: 5,
						indexAccordeonActive: null,
						resourcesID: ['#groupForm'],
					},
				},
				{
					title: 'Tipos de atenciones',
					text: 'En esta sección observarás una tabla que contiene el listado de los tipos de atenciones donde podrás eliminar, editar, ver detalle, activar o desactivar un tipo de atención.',
					path: '/dashboard/attentions/type-of-attentions',
					clipPath: {
						cut: 7,
						indexAccordeonActive: null,
						resourcesID: ['#triggerPathTypesAttention', '#typeAttentionsTable'],
					},
				},
				{
					title: 'Tipos de atenciones',
					text: 'Para crear un nuevo tipo de atención, debes hacer click en el botón superior izquierdo.',
					path: '/dashboard/attentions/type-of-attentions',
					clipPath: {
						cut: 4,
						indexAccordeonActive: null,
						resourcesID: ['#newTypeAttentionsBtn'],
					},
				},
				{
					title: 'Tipos de atención',
					text: 'Luego de dar click al botón “Nuevo Tipo de Atención” se carga un formulario, debes tener en cuenta que para crear un tipo de atención es importante asociarlo a un grupo.',
					path: '/dashboard/attentions/type-of-attentions/create',
					clipPath: {
						cut: 5,
						indexAccordeonActive: null,
						resourcesID: ['#typeAttentionsForm'],
					},
				},
				{
					title: 'Aplicaciones',
					text: 'Podrás observar un listado de las aplicaciones donde solo puedes eliminar o editar. Para poder eliminar una aplicación, está no debe ser administrada directamente por Videolink.',
					path: '/dashboard/attentions/aplications',
					clipPath: {
						cut: 7,
						indexAccordeonActive: null,
						resourcesID: ['#triggerPathAplications', '#aplicationsTable'],
					},
				},
				{
					title: 'Aplicaciones',
					text: 'Para crear una nueva aplicación, debes hacer click en el botón superior izquierdo.',
					path: '/dashboard/attentions/aplications',
					clipPath: {
						cut: 4,
						indexAccordeonActive: null,
						resourcesID: ['#newAplicationBtn'],
					},
				},
				{
					title: 'Aplicaciones',
					text: 'Luego de dar click al botón “Nueva Aplicación” se carga un formulario, debes tener en cuenta que para crear una aplicación se debe realizar la configuración en su servidor y registrar aquí la URL. Al momento de crear la aplicación esta genera un token único.',
					path: '/dashboard/attentions/aplications/create',
					clipPath: {
						cut: 5,
						indexAccordeonActive: null,
						resourcesID: ['#aplicationsForm'],
					},
				},
			],
		},
	];

	constructor(
		private _router: Router,
		private _localStorageSrc: LocalstorageService,
		private _notificationSrc: ToastService,
		private _loadingSrc: LoadingService,
		private _apiSrc: ApiService
	) {}

	showTour() {
		if (!!!this._localStorageSrc.get('tourActive')) this._localStorageSrc.set('tourActive', 'true');
		this.isActive.next(true);
		window.location.reload();
	}

	hiddenTour() {
		if (!!this._localStorageSrc.get('tourActive')) this._localStorageSrc.remove('tourActive');
		const { module } = this.tourActive.getValue();
		this.isActive.next(false);

		if (module === 'attention') {
			this._router.navigateByUrl('/dashboard/attentions').finally(() => {
				window.location.reload();
			});
		}
	}

	async startTour({ url }: any) {
		try {
			if (!url.includes('/dashboard/attentions'))
				return this._notificationSrc.initiate({
					type: 'warning',
					title: 'Próximamente',
					content: 'Se habilitará el tour de este módulo.',
				});

			await this.getStatusTour();

			const { tour } = this.preferencesUser.getValue();

			console.log({ tour });

			this.tourActive.next({ ...this.tourActive.getValue(), module: 'attention' });

			if (tour.attention.step === 0 || tour.attention.step === 1) {
				this.showModalStart();
			} else {
				this.showModalRestart();
			}
		} catch (error) {}
	}

	async getStatusTour() {
		try {
			this._loadingSrc.initiate();
			const { preferences } = await lastValueFrom(this._apiSrc.get(`/pref/v2/user`));
			this.preferencesUser.next(preferences);
			this._loadingSrc.hide();
		} catch (error) {
			console.log({ error });

			this._loadingSrc.hide();
			this._notificationSrc.initiate({ type: 'error', title: '😯', content: 'Disculpe a ocurrido un error' });
		}
	}

	async updatePreferences({ module = '', step = '' }: any) {
		try {
			await lastValueFrom(this._apiSrc.put(`/pref/v2/user/stepOfModule`, { module, step }));
		} catch (error) {
			this._loadingSrc.hide();
			this._notificationSrc.initiate({ type: 'error', title: '😯', content: 'Disculpe a ocurrido un error' });
		}
	}

	async changeStatusNotification({ module }: { module: string } = { module: '' }) {
		try {
			if (module === '') return;
			await lastValueFrom(this._apiSrc.put(`/pref/v2/user/tutorialNotification`, { module }));
		} catch (error) {}
	}

	showModalStart() {
		this.actions.emit({
			action: 'showModal',
			config: {
				modalInfo: {
					type: 'start',
					img: '/assets/img/modals/1.svg',
					title: 'Bienvenido a Videolink',
					text: 'Comienza el tutorial y en minutos aprenderas a usar el admin de Videolink.',
					btnConfirm: 'Empezar',
				},
			},
		});
	}
	showModalRestart() {
		this.actions.emit({
			action: 'showModal',
			config: {
				modalInfo: {
					type: 'restart',
					img: '/assets/img/modals/4.svg',
					title: 'Secciones para ir',
					text: 'Puedes seleccionar si reiniciar el tutorial o continuar donde lo dejaste.',
					btnConfirm: 'Continuar tutorial',
					btnReset: 'Reiniciar tutorial',
				},
			},
		});
	}
	showModalFinish() {
		this.actions.emit({
			action: 'showModal',
			config: {
				modalInfo: {
					type: 'finish',
					img: '/assets/img/modals/2.svg',
					title: '¡Listo!',
					text: 'Acabas de terminar el tutorial de este modulo.',
					btnConfirm: 'Finalizar',
				},
			},
		});

		const moduleTourActive = this.tourActive.getValue();

		this.updatePreferences({ ...moduleTourActive, step: 1 }).then();
	}
	showModalExit() {
		this.actions.emit({
			action: 'showModal',
			config: {
				modalInfo: {
					type: 'exit',
					img: '/assets/img/modals/3.svg',
					title: '¿Estas seguro que quieres salir?',
					text: 'El tutorial de este modulo dura menos de 5 minutos.',
					btnConfirm: 'Si',
					btnCancel: 'No',
				},
			},
		});
	}

	async changeStep(action: 'next' | 'prev') {
		try {
			const moduleTourActive = this.tourActive.getValue();
			const contentData = this.dataTour.find((item) => item.module === moduleTourActive.module);

			if (!contentData || (moduleTourActive.step === 1 && action === 'prev')) return;

			if (moduleTourActive.step >= contentData.steps.length && action === 'next') return this.showModalFinish();

			action === 'next' ? moduleTourActive.step++ : moduleTourActive.step--;

			await this.updatePreferences({ ...moduleTourActive });

			this.tourActive.next({
				...moduleTourActive,
			});
		} catch (error) {}
	}
}
