import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface IToastData {
	title: string;
	content: string;
	show?: boolean;
	type?: string;
	progressWidth?: string;
}

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	public stack: Array<{
		type: string;
		title?: string;
		content?: string;
		time?: number;
	}> = [];
	public executeWith = new Subject<
		Array<{
			type: string;
			title?: string;
			content?: string;
			time?: number;
		}>
	>();

	private isFirst: boolean = true;

	initiate(data: { type: string; title?: string; content?: string; time?: number }) {
		this.stack = [...this.stack, data];
		if (this.isFirst) {
			this.isFirst = false;
			this.executeWith.next(this.stack);
		}
	}

	executionCompleted(data: { type: string; title?: string; content?: string; time?: number }) {
		this.stack = this.stack.filter((execute) => execute !== data);
		if (this.stack.length > 0) {
			this.executeWith.next(this.stack);
		} else {
			this.isFirst = true;
		}
	}
}
