import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { FbsdkService } from '@services/backend/fbsdk.service';
import { MembershipsLiveService } from '@services_shared/live/memberships-live.service';
import { lastValueFrom } from 'rxjs';

export const metaResolver: ResolveFn<boolean> = (
	route,
	state,
	metaSrc: FbsdkService = inject(FbsdkService),
	membershipSrc: MembershipsLiveService = inject(MembershipsLiveService),
	router: Router = inject(Router)
) => {
	return lastValueFrom(membershipSrc.getResources())
		.then(() => true)
		.catch(() => {
			router.navigate(['/']);
			return false;
		});
};
