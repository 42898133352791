<div class="vl-stepper">
	<div class="vl-stepper__headers">
		<ng-container *ngFor="let step of config; let i = index">
			<div [ngClass]="['vl-stepper__headers__section', stepActive > i ? 'pass' : '', stepActive === i ? 'selected' : '']">
				<div class="vl-stepper__headers__section__top">
					<div class="vl-stepper__headers__section__top__step">{{ i + 1 }}</div>
					<p class="vl-stepper__headers__section__top__label">{{ step.header }}</p>
				</div>
				<div class="vl-stepper__headers__section__bottom">
					<div [ngClass]="['vl-stepper__headers__section__bottom__content', stepActive === i ? 'selected' : '']" *ngIf="stepActive === i">
						<div ngClass="vl-stepper__headers__section__bottom__content__body">
							<ng-container [ngTemplateOutlet]="step.content"></ng-container>
						</div>
						<div ngClass="vl-stepper__headers__section__bottom__content__actions">
							<button
								class="vlbtn alt"
								(click)="stepActive = stepActive + 1"
								*ngIf="i + 1 !== config.length"
								[disabled]="!conditionsStep[step.keyConditionByNextStep]"
							>
								{{ step?.textNextStep || 'siguiente' }}
							</button>
							<button class="vlbtn alt" (click)="stepActive = stepActive - 1" *ngIf="i !== 0">
								{{ step?.textPrevStep || 'regresar' }}
							</button>
							<button
								class="vlbtn"
								*ngIf="i + 1 === config.length"
								[disabled]="!conditionsStep[step.keyConditionByLastAction]"
								(click)="step?.lastAction && step?.lastAction()"
							>
								{{ step?.textLastStep || 'crear' }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="vl-stepper__contents">
		<ng-container *ngFor="let step of config; let i = index">
			<div [ngClass]="['vl-stepper__contents__section']" *ngIf="stepActive === i">
				<div ngClass="vl-stepper__contents__section__content">
					<ng-container [ngTemplateOutlet]="step.content"></ng-container>
				</div>
				<div ngClass="vl-stepper__contents__section__actions">
					<button
						class="vlbtn alt"
						(click)="stepActive = stepActive + 1"
						*ngIf="i + 1 !== config.length"
						[disabled]="!conditionsStep[step.keyConditionByNextStep]"
					>
						{{ step?.textNextStep || 'siguiente' }}
					</button>
					<button class="vlbtn alt" (click)="stepActive = stepActive - 1" *ngIf="i !== 0">{{ step?.textPrevStep || 'regresar' }}</button>
					<button
						class="vlbtn"
						*ngIf="i + 1 === config.length"
						[disabled]="!conditionsStep[step.keyConditionByLastAction]"
						(click)="step?.lastAction && step?.lastAction()"
					>
						{{ step?.textLastStep || 'crear' }}
					</button>
				</div>
			</div>
		</ng-container>
	</div>
</div>
