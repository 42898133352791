<!-- {{ customFields | json }} -->
<form [formGroup]="form" class="vl-contact">
    <div class="vl-contact-form" *ngIf="done">
      <div
        class="hk-form-fld"
        *ngFor="let item of customFields; let i = index"
        [ngStyle]="{ order: item.order }"
      >
        <label class="hk-form-fld-label" [for]="'fld-' + i"
          >{{ item.placeholder }} {{ item.required ? '*' : '' }}</label
        >
        <input
          class="hk-form-fld-input"
          [type]="['age', 'document'].includes(item.type) ? 'number' : 'text'"
          [name]="'fld-' + i"
          [id]="'fld-' + i"
          [placeholder]="item.example"
          />
      </div>
    </div>
    <div class="vl-button-row">
      <app-forms-contact-btn [background]="btnColor" >Volver</app-forms-contact-btn>
      <app-forms-contact-btn [background]="btnColor"  [disabled]="form.invalid" 
        >Enviar</app-forms-contact-btn
      >
    </div>
  </form>
  