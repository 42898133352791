<div class="component">
  <div class="header">
    <img
      class="user-icon"
      src="https://www.stickpng.com/assets/images/585e4beacb11b227491c3399.png"
    />
    <h2>Header del componente</h2>
  </div>
  <div class="body">
    <p class="center-text">Cuerpo del componente</p>
  </div>

  <div class="footer">
    <small class="right-text">Footer del componente</small>
  </div>
</div>
