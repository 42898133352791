import { DomSanitizer } from '@angular/platform-browser';

export const getBase64 = ($event: Blob, sanatizer: DomSanitizer): Promise<{ file: any; base: any }> => {
	return new Promise((resolve, reject) => {
		const refFile = window.URL.createObjectURL($event);
		const file = sanatizer.bypassSecurityTrustResourceUrl(refFile);
		const reader = new FileReader();

		reader.readAsDataURL($event);

		reader.onload = () => {
			resolve({
				file,
				base: reader.result,
			});
		};
		reader.onerror = () => {
			reject({
				file: null,
				base: null,
			});
		};
	});
};
