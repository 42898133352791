<a
	*wTP="config.permissions"
	[routerLink]="config.url"
	routerLinkActive="active"
	class="pageNav"
	[ngClass]="config.mode === 'subItem' ? 'subItem' : ''"
>
	<span class="pageNav__icon">
		<vl-icon [name]="config.iconName"></vl-icon>
	</span>
	<h2 class="pageNav__title">{{ config.label }}</h2>
</a>
