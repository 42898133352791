import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	constructor(private apiService: ApiService) {}

	login(usercredentials: { email: string; password: string }) {
		return this.apiService.post('/auth/login', { ...usercredentials, loginAs: 'admin' });
	}

	sendLinkEmailReset(resetData: { callback: string; email: string; type: string }) {
		return this.apiService.post('/pwd/reset', resetData);
	}

	validToken(data: { token: string }) {
		return this.apiService.post('/pwd/valid', data);
	}

	changePassword(data: { token: string; password: string }) {
		return this.apiService.post('/pwd/change_password', data);
	}
}
