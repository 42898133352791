<div
	class="box_option"
	[ngClass]="[
		config?.multiple ? 'multi' : '',
		config?.multiple && config?.withIcon ? 'withIcon' : '',
		!config?.multiple && config?.withIcon ? 'withIcon' : '',
		customClass ? customClass : ''
	]"
>
	<ng-container *ngIf="config?.multiple">
		<div class="v-option_check" [ngClass]="[selected ? 'active' : '']">
			<svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M11.4286 0.102539H0.571494C0.419867 0.102539 0.274554 0.162711 0.167411 0.269854C0.0602672 0.376997 0 0.522394 0 0.673937V11.531C0 11.6827 0.0602672 11.828 0.167411 11.9351C0.274554 12.0423 0.419877 12.1025 0.571494 12.1025H11.4286C11.5801 12.1025 11.7255 12.0423 11.8327 11.9351C11.9398 11.828 12 11.6827 12 11.531V0.673937C12 0.522406 11.9398 0.376997 11.8327 0.269854C11.7255 0.162711 11.5801 0.102539 11.4286 0.102539V0.102539ZM8.97716 4.50827L5.54858 7.93686C5.44153 8.04333 5.2967 8.10303 5.14574 8.10303C4.99478 8.10303 4.84995 8.04333 4.74291 7.93686L3.02861 6.22257C2.90263 6.07543 2.85977 5.87454 2.91468 5.68886C2.96959 5.50308 3.1149 5.35785 3.30059 5.30295C3.48627 5.24794 3.68726 5.29089 3.8343 5.41688L5.14289 6.72547L8.16568 3.70268C8.31281 3.5767 8.51371 3.53374 8.69949 3.58875C8.88517 3.64366 9.03049 3.78888 9.0854 3.97466C9.14031 4.16034 9.09745 4.36124 8.97147 4.50837L8.97716 4.50827Z"
					class="withFill"
				/>
			</svg>
		</div>
	</ng-container>

	<ng-container *ngIf="config?.onlyIcon; else default">
		<div class="v-option_icon">
			<v-icon [name]="item.icon"></v-icon>
		</div>
	</ng-container>

	<ng-template #default>
		<div class="v-option_icon" *ngIf="item.icon && config?.withIcon">
			<v-icon [name]="item.icon" class="icon"></v-icon>
		</div>

		<div class="v-option_name">
			{{ item.name }}
		</div>
	</ng-template>
</div>
