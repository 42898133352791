import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VlIconModule } from '@components_shared/vl-icon/vl-icon.module';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { WithThesePermissionModule } from '@directives/with-these-permission/with-these-permission.module';

@Component({
	selector: 'sub-menu-select',
	templateUrl: './sub-menu-select.component.html',
	styleUrls: ['./sub-menu-select.component.scss'],
	standalone: true,
	imports: [VlIconModule, RouterModule, CommonModule, MenuItemComponent, WithThesePermissionModule],
})
export class SubMenuSelectComponent implements OnInit {
	@Input('config') config: {
		label: string;
		iconName: string;
		params?: object;
		url: Array<string>;
		children: Array<any>;
		permissions: Array<string>;
	} = {
		label: '',
		iconName: '',
		url: [''],
		children: [],
		permissions: ['*'],
	};

	@Output() onClose = new EventEmitter();
	constructor() {}

	ngOnInit(): void {}
}
