import { Injectable, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DimensionsScreenService } from '@services_shared/global/dimensions-screen.service';
import { LoaderService } from '@services_shared/global/loader.service';
import { LocalStorageService } from '@services_shared/global/local-storage.service';
import { NotificationService } from '@services_shared/global/notification.service';
import { WebSocketService } from '@services_shared/global/web-socket.service';
import { Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class vlComponentWithSuscription implements OnDestroy {
	public screenWidth: number = this.dimensionsScreenScr.data.getValue().width;
	public _unSuscribeAll: Subject<boolean> = new Subject();
	public componentName: string = '';
	public isLoading: boolean = this.loaderSrc.loading.getValue();
	public module: string = '';
	public accessWith: Array<string> = ['*'];
	public localStorageSrc: LocalStorageService = inject(LocalStorageService);

	public webSocketSrc: WebSocketService = inject(WebSocketService);

	constructor(
		private _router: Router = inject(Router),
		private _route: ActivatedRoute = inject(ActivatedRoute),
		private _dimensionsScreenScr: DimensionsScreenService = inject(DimensionsScreenService),
		private _loadingSrc: LoaderService = inject(LoaderService),
		private _notificationSrc: NotificationService = inject(NotificationService)
	) {
		this.withSuscription(this.loaderSrc.loading).subscribe((isLoading) => (this.isLoading = isLoading));
		this.withSuscription(this.dimensionsScreenScr.data).subscribe(({ width }) => (this.screenWidth = width));
		this.showLoader = this.showLoader.bind(this);
		this.hiddenLoader = this.hiddenLoader.bind(this);
		this.showNotification = this.showNotification.bind(this);
	}

	get router() {
		return this._router;
	}

	get route() {
		return this._route;
	}
	get dimensionsScreenScr() {
		return this._dimensionsScreenScr;
	}
	get loaderSrc() {
		return this._loadingSrc;
	}
	get notificationSrc() {
		return this._notificationSrc;
	}

	public showLoader(): void {
		this.loaderSrc.show(this.module);
	}
	public hiddenLoader(): void {
		this.loaderSrc.hidden(this.module);
	}

	public showNotification(notification: vlNotification) {
		this.notificationSrc.show(notification);
	}

	ngOnDestroy(): void {
		this._unSuscribeAll.next(true);
		this._unSuscribeAll.complete();
		this.destroy();
	}

	destroy(): void {}

	withSuscription($resource: Observable<any>) {
		return $resource.pipe(takeUntil(this._unSuscribeAll));
	}
}
