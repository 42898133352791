export const environment = {
	production: false,
	hmr: false,
	logo: 'assets/images/logos/videolink_logo.png',
	serverRemote: 'https://api.qa.videolink.app',
	storage: 'https://cdn.qa.videolink.app',
	wsServer: 'https://ws.qa.videolink.app/videolink',
	widgetCss: 'https://widgetqa.s3.amazonaws.com/assets/css/style.css',
	widgetJs: 'https://widgetqa.s3.amazonaws.com/assets/js/script.js',
	easyServer: 'https://easy.qa.videolink.app',
	agentURL: 'https://agent.qa.videolink.app',
	adminURL: 'https://adminv2.qa.videolink.app',
	facebookAppId: '504867562500720',
	hookJs: 'https://s3.amazonaws.com/cdn1.videolink.app/videolink.js',
	hookCss: 'https://s3.amazonaws.com/cdn1.videolink.app/styles.css',
	waLink: 'https://wa.qa.videolink.app/wa/',
	lukaUser: 'videolink-suscripcion',
	lukaAuthQA: 'https://bspaycoapi-qa.payco.net.ve/api/v1/servicio/login',
	lukaAuthPRO: 'https://lukaapi.payco.net.ve/api/v1/servicio/login',
	lukaPasswordQA: 'come-HOME-hello-water',
	lukaPasswordPRO: 'london-OUTSIDE-five-FIJI',
	termsAndCondiitons: 'https://easy.qa.videolink.app/conditions',
	cdnWidget: 'https://cdn1.qa.videolink.app/widget',
	//
	apiServer: 'https://api.qa.videolink.app',
	adminUrl: 'https://adminv2.qa.videolink.app',
	agentUrl: 'https://agent.qa.videolink.app',
	easyUrl: 'https://easy.qa.videolink.app',
	tokenAppSupport: 'e66eb7d2207183e1eceff7e3325116e3d0c6e45382856aaf3323b42eaaaf4a6c',
	cdnWidgetSuppert: 'https://cdn1.qa.videolink.app/widget/autoload.js',
};
