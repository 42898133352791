import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	public modules: { [key: string]: boolean } = {};
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() {}

	show(module: string) {
		this.modules[module] = true;
		this.checkStatus();
	}
	hidden(module: string) {
		this.modules[module] = false;
		this.checkStatus();
	}

	checkStatus() {
		this.loading.next(Object.entries(this.modules).some(([module, status]) => status === true));
	}
}
