<div class="toast_wrapper" [@openClose]="show ? 'open' : 'closed'">
	<div class="toastNotification error" *ngIf="data_info.type == 'error'">
		<div class="toastNotification__icon">
			<svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M19.0207 35.2114C28.1632 35.2114 35.5747 27.8 35.5747 18.6575C35.5747 9.51497 28.1632 2.10352 19.0207 2.10352C9.87825 2.10352 2.4668 9.51497 2.4668 18.6575C2.4668 27.8 9.87825 35.2114 19.0207 35.2114Z"
					stroke-width="4"
				/>
				<path
					d="M12.8447 11.6911C12.6412 11.6911 12.438 11.769 12.2822 11.923C11.9723 12.228 11.9723 12.7363 12.2822 13.0461L24.637 25.3962C24.9453 25.7013 25.4503 25.7013 25.7602 25.3962C26.0684 25.0911 26.0684 24.5781 25.7602 24.2731L13.4053 11.923C13.2513 11.769 13.0479 11.6911 12.8444 11.6911H12.8447Z"
					stroke-width="2"
				/>
				<path
					d="M25.9867 12.4824C25.9867 12.2789 25.9087 12.0757 25.7548 11.9199C25.4497 11.61 24.9415 11.61 24.6316 11.9199L12.2815 24.2747C11.9765 24.583 11.9765 25.088 12.2815 25.3979C12.5866 25.7061 13.0996 25.7061 13.4047 25.3979L25.7548 13.043C25.9087 12.889 25.9867 12.6856 25.9867 12.4821L25.9867 12.4824Z"
					stroke-width="2"
				/>
			</svg>
		</div>
		<p class="toastNotification__title">{{ data_info.title }}</p>
		<p class="toastNotification__info">{{ data_info.content }}</p>
		<button class="toastNotification__btn--close" (click)="close()">
			<svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M19.0207 35.2114C28.1632 35.2114 35.5747 27.8 35.5747 18.6575C35.5747 9.51497 28.1632 2.10352 19.0207 2.10352C9.87825 2.10352 2.4668 9.51497 2.4668 18.6575C2.4668 27.8 9.87825 35.2114 19.0207 35.2114Z"
					stroke-width="4"
				/>
				<path
					d="M12.8447 11.6911C12.6412 11.6911 12.438 11.769 12.2822 11.923C11.9723 12.228 11.9723 12.7363 12.2822 13.0461L24.637 25.3962C24.9453 25.7013 25.4503 25.7013 25.7602 25.3962C26.0684 25.0911 26.0684 24.5781 25.7602 24.2731L13.4053 11.923C13.2513 11.769 13.0479 11.6911 12.8444 11.6911H12.8447Z"
					stroke-width="2"
				/>
				<path
					d="M25.9867 12.4824C25.9867 12.2789 25.9087 12.0757 25.7548 11.9199C25.4497 11.61 24.9415 11.61 24.6316 11.9199L12.2815 24.2747C11.9765 24.583 11.9765 25.088 12.2815 25.3979C12.5866 25.7061 13.0996 25.7061 13.4047 25.3979L25.7548 13.043C25.9087 12.889 25.9867 12.6856 25.9867 12.4821L25.9867 12.4824Z"
					stroke-width="2"
				/>
			</svg>
		</button>
		<div class="toastNotification__bar"></div>
	</div>
	<div class="toastNotification info" *ngIf="data_info.type == 'success'">
		<div class="toastNotification__icon">
			<svg viewBox="0 0 40 39" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M33.8955 22.9745L29.7119 12.291C29.2501 11.1115 27.9621 10.5118 26.767 10.9798C26.0263 11.2699 24.7691 12.255 25.4385 13.9644L23.7651 9.69099M23.7651 9.69099C23.3032 8.51153 22.0152 7.91184 20.8201 8.37985C20.0979 8.66266 18.8223 9.65507 19.4916 11.3644M23.7651 9.69099L26.833 17.5256M19.4916 11.3644L18.376 8.51549C17.9141 7.33603 16.6262 6.73634 15.4311 7.20435C14.2345 7.67291 13.6407 9.00947 14.1026 10.1889L15.2182 13.0379M19.4916 11.3644L22.5596 19.199M15.2182 13.0379C14.5488 11.3285 12.967 11.4551 12.2448 11.7379C11.0482 12.2065 10.4935 13.5589 10.9559 14.7398L15.6862 26.8194M15.2182 13.0379L18.2862 20.8725M33.8963 22.9759C36.1276 28.6738 32.7789 33.271 27.2719 35.4275C21.7649 37.584 19.3125 36.9014 12.25 31.4525L8.55904 28.6181C7.31134 27.6806 7.29434 25.8374 8.52322 24.8304C8.92454 24.4999 9.4178 24.3007 9.93613 24.26C10.4545 24.2192 10.9728 24.3388 11.4208 24.6026L15.7054 26.8678"
					stroke-width="3"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M27.3522 5.48077C27.8118 5.54856 28.2739 5.64776 28.7358 5.78022C31.2121 6.49043 33.0667 7.98066 34.1377 9.79567L36.5502 10.0101C35.3988 7.20623 32.8583 4.88678 29.4172 3.89988C28.4321 3.61735 27.4351 3.46181 26.4488 3.42308L27.3522 5.48077Z"
					fill="#4B27EF"
					stroke-width="0"
				/>
				<path
					d="M5.92792 20.0142C5.70677 19.5975 5.52561 19.1608 5.38907 18.7075C4.74374 16.565 5.16994 14.2999 6.45089 12.4482L5.34162 10.525C3.31902 12.9071 2.50777 16.0643 3.44358 19.1713C3.7114 20.0604 4.10546 20.8895 4.604 21.6474L5.92792 20.0142Z"
					fill="#4B27EF"
					stroke-width="0"
				/>
				<path
					d="M26.9398 2.57736C27.709 2.66754 28.4834 2.8212 29.2558 3.0427C32.8138 4.06313 35.4619 6.27504 36.9046 8.96263L39.2908 9.17472C37.7809 5.498 34.4495 2.4565 29.9372 1.16237C28.6452 0.791816 27.3377 0.587857 26.0441 0.537109L26.9398 2.57736Z"
					fill="#4B27EF"
					stroke-width="0"
				/>
				<path
					d="M3.41481 21.8946C3.04159 21.2359 2.73992 20.5377 2.51978 19.8068C1.58365 16.6988 2.25636 13.4467 4.16684 10.8446L3.06367 8.93199C0.411072 12.0557 -0.65293 16.1961 0.574285 20.2706C0.925744 21.4374 1.44299 22.5254 2.09743 23.5198L3.41481 21.8946Z"
					fill="#4B27EF"
					stroke-width="0"
				/>
			</svg>
		</div>
		<p class="toastNotification__title">{{ data_info.title }}</p>
		<p class="toastNotification__info">{{ data_info.content }}</p>
		<button class="toastNotification__btn--close" (click)="close()">
			<svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M19.0207 35.2114C28.1632 35.2114 35.5747 27.8 35.5747 18.6575C35.5747 9.51497 28.1632 2.10352 19.0207 2.10352C9.87825 2.10352 2.4668 9.51497 2.4668 18.6575C2.4668 27.8 9.87825 35.2114 19.0207 35.2114Z"
					stroke-width="4"
				/>
				<path
					d="M12.8447 11.6911C12.6412 11.6911 12.438 11.769 12.2822 11.923C11.9723 12.228 11.9723 12.7363 12.2822 13.0461L24.637 25.3962C24.9453 25.7013 25.4503 25.7013 25.7602 25.3962C26.0684 25.0911 26.0684 24.5781 25.7602 24.2731L13.4053 11.923C13.2513 11.769 13.0479 11.6911 12.8444 11.6911H12.8447Z"
					stroke-width="2"
				/>
				<path
					d="M25.9867 12.4824C25.9867 12.2789 25.9087 12.0757 25.7548 11.9199C25.4497 11.61 24.9415 11.61 24.6316 11.9199L12.2815 24.2747C11.9765 24.583 11.9765 25.088 12.2815 25.3979C12.5866 25.7061 13.0996 25.7061 13.4047 25.3979L25.7548 13.043C25.9087 12.889 25.9867 12.6856 25.9867 12.4821L25.9867 12.4824Z"
					stroke-width="2"
				/>
			</svg>
		</button>
		<div class="toastNotification__bar"></div>
	</div>
	<div class="toastNotification alert" *ngIf="data_info.type == 'warning'">
		<div class="toastNotification__icon">
			<svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M19.1887 35.7212C28.3312 35.7212 35.7427 28.3097 35.7427 19.1672C35.7427 10.0247 28.3312 2.61328 19.1887 2.61328C10.0462 2.61328 2.63477 10.0247 2.63477 19.1672C2.63477 28.3097 10.0462 35.7212 19.1887 35.7212Z"
					stroke-width="4"
				/>
				<path
					d="M19.1887 24.0046L19.1887 24.6586M19.1895 11.5781L19.189 19.4264L19.1895 11.5781Z"
					stroke-width="4"
					stroke-linecap="round"
				/>
			</svg>
		</div>
		<p class="toastNotification__title">{{ data_info.title }}</p>
		<p class="toastNotification__info">{{ data_info.content }}</p>
		<button class="toastNotification__btn--close" (click)="close()">
			<svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M19.0207 35.2114C28.1632 35.2114 35.5747 27.8 35.5747 18.6575C35.5747 9.51497 28.1632 2.10352 19.0207 2.10352C9.87825 2.10352 2.4668 9.51497 2.4668 18.6575C2.4668 27.8 9.87825 35.2114 19.0207 35.2114Z"
					stroke-width="4"
				/>
				<path
					d="M12.8447 11.6911C12.6412 11.6911 12.438 11.769 12.2822 11.923C11.9723 12.228 11.9723 12.7363 12.2822 13.0461L24.637 25.3962C24.9453 25.7013 25.4503 25.7013 25.7602 25.3962C26.0684 25.0911 26.0684 24.5781 25.7602 24.2731L13.4053 11.923C13.2513 11.769 13.0479 11.6911 12.8444 11.6911H12.8447Z"
					stroke-width="2"
				/>
				<path
					d="M25.9867 12.4824C25.9867 12.2789 25.9087 12.0757 25.7548 11.9199C25.4497 11.61 24.9415 11.61 24.6316 11.9199L12.2815 24.2747C11.9765 24.583 11.9765 25.088 12.2815 25.3979C12.5866 25.7061 13.0996 25.7061 13.4047 25.3979L25.7548 13.043C25.9087 12.889 25.9867 12.6856 25.9867 12.4821L25.9867 12.4824Z"
					stroke-width="2"
				/>
			</svg>
		</button>
		<div class="toastNotification__bar"></div>
	</div>
	<div class="toastNotification limit" *ngIf="data_info.type == 'space-caution'">
		<div class="toastNotification__icon">
			<svg xmlns="http://www.w3.org/2000/svg" width="36" height="28" viewBox="0 0 36 28" fill="none">
				<path d="M12.5891 22.5674C12.5036 22.5674 12.4074 22.5674 12.3166 22.5674C10.8469 22.5674 9.37726 22.5727 7.90761 22.5674C4.96831 22.562 2.68099 20.6809 2.09848 17.8057C1.64422 15.5558 2.79856 13.012 4.79729 11.8576C5.36378 11.5263 5.96232 11.2804 6.60363 11.1522C6.78533 11.1148 6.8548 11.0453 6.87084 10.8582C7.14873 8.08461 8.34049 5.76523 10.414 3.91614C11.8677 2.62285 13.5724 1.7945 15.4964 1.46316C18.6334 0.923393 21.4712 1.63951 23.9776 3.60083C25.7519 4.99032 26.9276 6.78597 27.5475 8.95037C27.617 9.1962 27.7292 9.31377 27.975 9.38859C29.4351 9.81192 30.6493 10.7018 31.4926 11.8576" stroke="#D43224" stroke-width="2.35349" stroke-miterlimit="10" stroke-linecap="round"/>
				<path d="M24.6878 14.5513C24.5282 14.5513 24.3688 14.6124 24.2465 14.7332C24.0034 14.9726 24.0034 15.3713 24.2465 15.6143L33.9388 25.3029C34.1807 25.5423 34.5769 25.5423 34.8199 25.3029C35.0618 25.0636 35.0618 24.6611 34.8199 24.4218L25.1276 14.7332C25.0068 14.6124 24.8472 14.5513 24.6876 14.5513H24.6878Z" fill="#D43224" stroke="#D43224" stroke-width="1.56899"/>
				<path d="M35.0019 15.1724C35.0019 15.0128 34.9408 14.8534 34.82 14.7311C34.5807 14.4881 34.182 14.4881 33.9389 14.7311L24.2503 24.4235C24.011 24.6653 24.011 25.0615 24.2503 25.3046C24.4896 25.5464 24.8921 25.5464 25.1314 25.3046L34.82 15.6122C34.9408 15.4914 35.0019 15.3318 35.0019 15.1722L35.0019 15.1724Z" fill="#D43224" stroke="#D43224" stroke-width="1.56899"/>
				<path d="M18.9629 26.7092V15.334" stroke="#D43224" stroke-width="2.35349" stroke-linecap="round"/>
				<path d="M16.219 18.0797L18.9647 15.334L21.7105 18.0797" stroke="#D43224" stroke-width="2.35349" stroke-linecap="round"/>
				</svg>
		</div>
		<p class="toastNotification__titlecustom">{{ data_info.title }}</p>
		<button class="toastNotification__btn--close" (click)="close()">
			<svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M19.0207 35.2114C28.1632 35.2114 35.5747 27.8 35.5747 18.6575C35.5747 9.51497 28.1632 2.10352 19.0207 2.10352C9.87825 2.10352 2.4668 9.51497 2.4668 18.6575C2.4668 27.8 9.87825 35.2114 19.0207 35.2114Z"
					stroke-width="4"
				/>
				<path
					d="M12.8447 11.6911C12.6412 11.6911 12.438 11.769 12.2822 11.923C11.9723 12.228 11.9723 12.7363 12.2822 13.0461L24.637 25.3962C24.9453 25.7013 25.4503 25.7013 25.7602 25.3962C26.0684 25.0911 26.0684 24.5781 25.7602 24.2731L13.4053 11.923C13.2513 11.769 13.0479 11.6911 12.8444 11.6911H12.8447Z"
					stroke-width="2"
				/>
				<path
					d="M25.9867 12.4824C25.9867 12.2789 25.9087 12.0757 25.7548 11.9199C25.4497 11.61 24.9415 11.61 24.6316 11.9199L12.2815 24.2747C11.9765 24.583 11.9765 25.088 12.2815 25.3979C12.5866 25.7061 13.0996 25.7061 13.4047 25.3979L25.7548 13.043C25.9087 12.889 25.9867 12.6856 25.9867 12.4821L25.9867 12.4824Z"
					stroke-width="2"
				/>
			</svg>
		</button>
		<div class="toastNotification__bar"></div>
	</div>
</div>
