import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'vl-layout-content-s2',
	templateUrl: './vl-layout-content-s2.component.html',
	styleUrls: ['./vl-layout-content-s2.component.scss'],
})
export class VlLayoutContentS2Component implements OnInit {
	@Input() navigateTo: Array<string> = ['..'];
	@Input() cTitle: string = '';

	constructor() {}

	ngOnInit(): void {}
}
