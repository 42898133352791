<div class="vl-form-group-s1">
	<label class="vl-form-group-s1__label" [for]="id" *ngIf="type !== 'check' && type !== 'check-invert'">{{ label }}</label>
	<div class="vl-form-group-s1__wrapper">
		<label class="vl-form-group-s1__wrapper__content" [for]="id">
			<!-- Dinamic -->
			<ng-container *ngIf="type === 'text' || type === 'number'">
				<input
					formInputTrim
					class="vl-form-group-s1__wrapper__content__input"
					[(ngModel)]="value"
					(ngModelChange)="onChange($event)"
					(blur)="onTouched()"
					[disabled]="isDisabled || disabled"
					[id]="id"
					[type]="type"
					[placeholder]="placeholder || label"
					[maxlength]="maxlength"
					[minlength]="minlength"
					[max]="max"
					[min]="min"
				/>
			</ng-container>
			<ng-container *ngIf="type === 'tel'">
				<input
					formInputTrim
					class="vl-form-group-s1__wrapper__content__input"
					[(ngModel)]="value"
					(ngModelChange)="onChange($event)"
					(blur)="onTouched()"
					[disabled]="isDisabled || disabled"
					[id]="id"
					[type]="type"
					[placeholder]="placeholder || label"
					[maxlength]="maxlength"
					[minlength]="minlength"
					[max]="max"
					[min]="min"
					type="tel"
					oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
				/>
			</ng-container>
			<ng-container *ngIf="type === 'select-single'">
				<vl-select-custom-s2
					[value]="this.value"
					(changeSelection)="this.value = $event; onChange($event)"
					[config]="{
						type: 'text',
						options: optionsoFSelect
					}"
				></vl-select-custom-s2>
			</ng-container>
			<ng-container *ngIf="type === 'select-multi'">
				<vl-select-custom-s2
					[value]="this.value"
					(changeSelection)="this.value = $event; onChange($event)"
					[config]="{
						type: 'text',
						isMulti: true,
						options: optionsoFSelect
					}"
				></vl-select-custom-s2>
			</ng-container>
			<ng-container *ngIf="type === 'select-icon'">
				<vl-select-custom-s2
					[value]="this.value"
					(changeSelection)="this.value = $event; onChange($event)"
					[config]="{
						type: 'icon',
						options: optionsoFSelect
					}"
				></vl-select-custom-s2>
			</ng-container>
			<ng-container *ngIf="type === 'check'">
				<button type="button" class="vl-checkbox-form" (click)="checkChangeValue()">
					<label class="vl-checkbox-form__wrapper">
						<input type="checkbox" [checked]="this.value" disabled />
						<span class="vl-checkbox-form__wrapper__check"></span>
					</label>
					<p class="vl-checkbox-form__text">{{ checkMsg }}</p>
				</button>
			</ng-container>
			<ng-container *ngIf="type === 'check-invert'">
				<button type="button" class="vl-checkbox-form" (click)="checkChangeValue()">
					<label class="vl-checkbox-form__wrapper">
						<input type="checkbox" [checked]="this.value === false" disabled />
						<span class="vl-checkbox-form__wrapper__check"></span>
					</label>
					<p class="vl-checkbox-form__text">{{ checkMsg }}</p>
				</button>
			</ng-container>
		</label>
		<p class="vl-form-group-s1__wrapper__error">{{ hasErrors }}</p>
	</div>
</div>
