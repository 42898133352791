import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ILoadingData {
  loading: boolean
}

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  data!: ILoadingData;
  public open = new Subject<ILoadingData>();

  initiate() {
   
    this.data = {loading: true };
    this.open.next(this.data);
  }

  hide() {
    this.data = { loading: false };
    this.open.next(this.data);
  }
}
