import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hk-btn-abilities',
  templateUrl: './preview-attention-btn.component.html',
  styleUrls: ['./preview-attention-btn.component.scss']
})
export class PreviewAttentionBtnComponent {
  @Input('disabled') disabled: boolean = false
  @Input('color') color: string = ''
  @Input('background') background: string = ''
  @Input('sizeButton') sizeButton: string = '76px !important'
  constructor() {}
}
