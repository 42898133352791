<div class="selectNav" routerLinkActive="active" *wTP="config.permissions">
	<button class="selectNav__header" [routerLink]="config.url" routerLinkActive="active">
		<span class="selectNav__header__icon">
			<vl-icon [name]="config.iconName"></vl-icon>
		</span>
		<h2 class="selectNav__header__title">{{ config.label }}</h2>
		<span class="selectNav__header__arrow">
			<svg viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.62171 6.99458C6.87757 6.96991 7.11951 6.86657 7.31423 6.69895L12.5434 2.21677C12.8066 2.0059 12.9728 1.69722 13.0044 1.3615C13.0359 1.02575 12.9299 0.691754 12.7105 0.435541C12.4913 0.17934 12.1775 0.0230156 11.841 0.00234327C11.5044 -0.0183264 11.1738 0.0983995 10.9248 0.325937L6.50493 4.11565L2.08509 0.325936C1.836 0.0985677 1.50547 -0.0181562 1.16886 0.00251603C0.832413 0.0230122 0.518545 0.179339 0.299327 0.43554C0.0799491 0.69174 -0.026005 1.02592 0.00543251 1.36168C0.0370452 1.69743 0.203271 2.00591 0.466413 2.21676L5.69563 6.69895C5.95148 6.91902 6.28569 7.02567 6.62159 6.99475L6.62171 6.99458Z"
					fill="currentColor"
				/>
			</svg>
		</span>
	</button>
	<div class="selectNav__wrapper">
		<div class="selectNav__wrapper__body">
			<menu-item
				(click)="onClose.emit()"
				*ngFor="let child of config.children"
				[config]="{
					label: child.label,
					iconName: child.iconName,
					params: child.params,
					url: child.url,
					mode: child.mode,
					permissions: child.permissions || ['*']
				}"
			></menu-item>
		</div>
	</div>
</div>
