import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'v-sub-menu-item',
  templateUrl: './sub-menu-item.component.html',
  styleUrls: ['./sub-menu-item.component.scss']
})
export class SubMenuItemComponent implements OnInit {
  @Output('clicked') clicked = new EventEmitter()
  @Input('id') id: string = ''
  @Input() data!: {
    icon: string,
    link: Array<string>
  }
  constructor() { }

  ngOnInit(): void {
  }
  onClick(ev: any) {
    this.clicked.emit(ev)
  }
}
