import { DimensionsScreenService } from '@services/components/dimensions-screen.service';
import { Component, HostListener, NgZone, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService } from '@services_shared/global/auth.service';
import { DimensionsScreenService as DimensionsScreen } from '@services_shared/global/dimensions-screen.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.dimensionsScreenSrc.change.next({ ...this.dimensionsScreenSrc.data, width: window.innerWidth, height: window.innerHeight });
		this._dimensionsScreenSrc.data.next({ ...this._dimensionsScreenSrc.data, width: window.innerWidth, height: window.innerHeight });
	}

	@HostListener('window:load', ['$event'])
	onWindowLoad() {
		this.dimensionsScreenSrc.change.next({ ...this.dimensionsScreenSrc.data, width: window.innerWidth, height: window.innerHeight });
		this._dimensionsScreenSrc.data.next({ ...this._dimensionsScreenSrc.data, width: window.innerWidth, height: window.innerHeight });
	}

	private _authService: AuthService = inject(AuthService);
	private _dimensionsScreenSrc: DimensionsScreen = inject(DimensionsScreen);

	constructor(private dimensionsScreenSrc: DimensionsScreenService, private ngZone: NgZone) {
		this.loadScriptContent();
	}

	loadScriptContent() {
		// const dataVideolink = document.createElement('videolink-wid');
		// const tokenSupport = environment.tokenAppSupport;
		const head = document.head;
		const scripts = [environment.cdnWidgetSuppert];
		// dataVideolink.setAttribute('token', tokenSupport);
		// dataVideolink.style.zIndex = '15000000';
		// dataVideolink.style.display = 'none';
		// document.body.append(dataVideolink);

		for (let i = 0; i < scripts.length; i++) {
			const src = scripts[i];
			const script = document.createElement('script');
			script.src = src;
			head.appendChild(script);
		}
	}
}
