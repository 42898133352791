import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DimensionsScreenService {
  data: BehaviorSubject<{ width: number; height: number }> =
    new BehaviorSubject({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  constructor() {}
}
