<main class="vl-header-s2">
	<div class="vl-header-s2__header">
		<a class="vl-header-s2__header__back" [routerLink]="navigateTo">
			<vl-icon name="vli_back"></vl-icon>
		</a>
		<h2 class="vl-header-s2__header__title">{{ cTitle }}</h2>
	</div>
	<div class="vl-header-s2__content">
		<ng-content></ng-content>
	</div>
</main>
