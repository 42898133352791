import { VlIconModule } from '@components/vl-icon/vl-icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlSelectCustomComponent } from './vl-select-custom.component';
import { VlOptionComponent } from './components/vl-option/vl-option.component';

@NgModule({
	declarations: [VlSelectCustomComponent, VlOptionComponent],
	imports: [CommonModule, VlIconModule],
	exports: [VlSelectCustomComponent],
})
export class VlSelectCustomModule {}
