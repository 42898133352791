import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlDatalistComponent } from './vl-datalist.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormInputTrimModule } from '@directives/form-input-trim/form-input-trim.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
	declarations: [VlDatalistComponent],
	imports: [CommonModule, ReactiveFormsModule, FormInputTrimModule, ScrollingModule],
	exports: [VlDatalistComponent],
})
export class VlDatalistModule {}
