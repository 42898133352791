<div class="vl-notification-wrapper">
	<ng-container *ngFor="let notification of notifications">
		<vl-toast
			@toastInScreem
			[id]="notification.id"
			[type]="notification.type"
			[icon]="notification.icon"
			[title]="notification.title"
			[msg]="notification.msg"
			[time]="notification.time"
		></vl-toast>
	</ng-container>
</div>
