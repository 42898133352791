<div class="tour__overlay" *ngIf="isTourActive || modalInView">
	<div class="tour__overlay__steps" id="overlayToCut">
		<dialog class="modalTour" [open]="true" *ngIf="modalInView">
			<button class="modalTour__btnClose" (click)="isTourActive ? closeModal({ exitTour: false }) : closeModal()">
				<v-icon name="v-close" class="icon"></v-icon>
			</button>
			<img class="modalTour__picture" [src]="modalInfo.img" />
			<div class="modalTour__info">
				<p class="modalTour__info__title">{{ modalInfo.title }}</p>
				<p class="modalTour__info__text">{{ modalInfo.text }}</p>
			</div>
			<div class="modalTour__actions">
				<ng-container [ngSwitch]="modalInfo.type">
					<ng-container *ngSwitchCase="'start'">
						<button class="modalTour__actions__btn" (click)="closeModal({ step: 1 })">{{ modalInfo.btnConfirm }}</button>
					</ng-container>
					<ng-container *ngSwitchCase="'finish'">
						<button class="modalTour__actions__btn" (click)="closeModal()">{{ modalInfo.btnConfirm }}</button>
					</ng-container>
					<ng-container *ngSwitchCase="'restart'">
						<button class="modalTour__actions__btn alt" (click)="closeModal({ step: 1 })">{{ modalInfo.btnReset }}</button>
						<button class="modalTour__actions__btn" (click)="closeModal({ step: lastStep === 0 ? 1 : lastStep })">
							{{ modalInfo.btnConfirm }}
						</button>
					</ng-container>
					<ng-container *ngSwitchCase="'exit'">
						<button class="modalTour__actions__btn small cancel" (click)="closeModal({ step: 1 })">
							{{ modalInfo.btnCancel }}
						</button>
						<button class="modalTour__actions__btn small" (click)="closeModal({ exitTour: true })">
							{{ modalInfo.btnConfirm }}
						</button>
					</ng-container>
				</ng-container>
			</div>
		</dialog>
	</div>
	<Steps [@Steps]="isTourActive && !modalInView ? 'show' : 'hidden'"></Steps>
</div>
