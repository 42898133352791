import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hk-tooltip-info',
  templateUrl: './tooltip-hk.component.html',
  styleUrls: ['./tooltip-hk.component.scss'],
})
export class TooltipHkComponent implements OnInit {
  @Input() text: string = ''
  @Input() position: 'center' | 'left' | 'right' = 'right'
  constructor() {}

  ngOnInit(): void {}
}
