<input #inputFile type="file" (change)="buildPreview($event)" accept="image/*" />

<button
	class="vl-box-upload-img"
	[ngStyle]="{ '--bgColorBoxImg': !value && !previewURL ? '#ffffff' : bgColorBoxImg }"
	(click)="inputFile.click()"
>
	<ng-container *ngIf="!previewURL && value">
		<img class="vl-box-upload-img__preview" [src]="value" alt="preview" />
	</ng-container>
	<ng-container *ngIf="previewURL">
		<img class="vl-box-upload-img__preview" [src]="previewURL" alt="preview" />
	</ng-container>

	<ng-container *ngIf="!value">
		<div class="vl-box-upload-img__notFile">
			<svg class="vl-box-upload-img__notFile__icon" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4.81303 8C4.69939 7.95312 4.63405 7.83825 4.65464 7.72168V3.54034H3.21877C2.98933 3.51851 2.9369 3.42109 3.07789 3.25172C3.91392 2.24371 4.75125 1.23813 5.59411 0.239058C5.86922 -0.0854316 5.99673 -0.0738884 6.28926 0.239058C7.15618 1.20609 8.02224 2.17356 8.8872 3.14156C8.93522 3.2125 8.97323 3.28935 9 3.36996C8.91762 3.4036 8.83104 3.42686 8.74229 3.43921C8.31825 3.4469 7.89418 3.43921 7.47004 3.43921H7.31175V7.72551C7.33037 7.84003 7.26101 7.95096 7.14671 7.9897H4.81302L4.81303 8Z"
					fill="currentColor"
				/>
				<path
					d="M0.00730957 8.00008H1.66333V10.5105H10.332V8.00107H11.9928C11.9928 8.03976 12 8.0773 12 8.11159V11.1867C12 11.6928 11.6532 11.9999 11.0868 11.9999H0.902462C0.456064 12.0043 0.141665 11.8032 0.0336011 11.4519C0.0105826 11.3737 -0.000718878 11.2931 3.54047e-05 11.2121V8.09395C3.54047e-05 8.06628 0.000110881 8.03769 0.00730957 8.00008Z"
					fill="currentColor"
				/>
			</svg>
			<p class="vl-box-upload-img__notFile__text">Cargar imagen</p>
		</div>
	</ng-container>
</button>
