export const environment = {
	production: false,
	hmr: false,
	logo: 'assets/images/logos/videolink_logo.png',
	easyServer: 'https://easy.qa.omniflou.com',
	agentURL: 'https://agent.qa.omniflou.com',
	adminURL: 'https://admin.qa.omniflou.com',
	serverRemote: 'https://api.qa.omniflou.com',
	wsServer: 'https://ws.qa.omniflou.com/videolink',
	storage: 'https://cdn.qa.li.omniflou.com',
	hookJs: 'https://cdn.qa.omniflou.com/videolink.js',
	hookCss: 'https://cdn.qa.omniflou.com/styles.css',
	widgetCss: 'https://widget.qa.omniflou.com/assets/css/style.css',
	widgetJs: 'https://widget.qa.omniflou.com/assets/js/script.js',
	facebookAppId: '504867562500720',
	waLink: 'https://wa.qa.omniflou.com/wa/',
	lukaUser: 'videolink-suscripcion',
	lukaAuthQA: 'https://bspaycoapi-qa.payco.net.ve/api/v1/servicio/login',
	lukaAuthPRO: 'https://lukaapi.payco.net.ve/api/v1/servicio/login',
	lukaPasswordQA: 'come-HOME-hello-water',
	lukaPasswordPRO: 'london-OUTSIDE-five-FIJI',
	termsAndCondiitons: 'https://easy.qa.omniflou.com/conditions',
	cdnWidget: 'https://widget.qa.omniflou.com',
	//
	apiServer: 'https://api.qa.omniflou.com',
	adminUrl: 'https://admin.qa.omniflou.com',
	agentUrl: 'https://agent.qa.omniflou.com',
	easyUrl: 'https://easy.qa.omniflou.com',
	tokenAppSupport: 'e66eb7d2207183e1eceff7e3325116e3d0c6e45382856aaf3323b42eaaaf4a6c',
	cdnWidgetSuppert: 'https://widget.qa.omniflou.com/autoload.js',
};
