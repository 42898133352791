import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, throwError } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private formatErrors(error: any) {
    return throwError(error.error)
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${environment.serverRemote}${path}`, {
        params,
      })
      .pipe(catchError(this.formatErrors))
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http
      .put(`${environment.serverRemote}${path}`, JSON.stringify(body))
      .pipe(catchError(this.formatErrors))
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http
      .post(`${environment.serverRemote}${path}`, JSON.stringify(body))
      .pipe(catchError(this.formatErrors))
  }

  delete(path: string): Observable<any> {
    return this.http
      .delete(`${environment.serverRemote}${path}`)
      .pipe(catchError(this.formatErrors))
  }
}
