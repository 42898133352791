import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter, tap } from 'rxjs';
import { LoadingService } from 'src/app/services/components/loading.service';

@Component({
	selector: 'v-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
	isLoadingHTTP: boolean = false;
	isLoadingLocal: boolean = false;

	loading: boolean = false;

	constructor(private loadingService: LoadingService, private router: Router) {
		this.router.events
			.pipe(
				filter(
					(event) =>
						event instanceof NavigationStart ||
						event instanceof NavigationEnd ||
						event instanceof NavigationCancel ||
						event instanceof NavigationError
				),
				tap((event) => {
					event instanceof NavigationStart ? (this.isLoadingLocal = true) : (this.isLoadingLocal = false);
					this.loading = this.isLoadingHTTP || this.isLoadingLocal ? true : false;
				})
			)
			.subscribe();
		this.loadingService.open.subscribe((data) => {
			this.isLoadingHTTP = data.loading;
			this.loading = this.isLoadingHTTP || this.isLoadingLocal ? true : false;
		});
	}
	ngOnInit(): void {}
}
