import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputTrimDirective } from './form-input-trim.directive';

@NgModule({
	declarations: [FormInputTrimDirective],
	imports: [CommonModule],
	exports: [FormInputTrimDirective],
})
export class FormInputTrimModule {}
