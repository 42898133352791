import { VIconModule } from './../v-icon/v-icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourComponent } from './tour.component';
import { StepsComponent } from './steps/steps.component';
import { TourService } from '@services/components/tour.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TourInterceptor } from 'src/app/interceptors/tour.interceptor';

@NgModule({
	declarations: [TourComponent, StepsComponent],
	imports: [CommonModule, VIconModule],
	exports: [TourComponent],
	providers: [TourService],
})
export class TourModule {}
