import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileUploadService } from '@services/backend/file-upload.service';
import { LoadingService } from '@services/components/loading.service';
// import { FileUplodadService } from '@services/global/file-uplodad.service';
import { lastValueFrom } from 'rxjs';

interface SelectValues {
	value: string;
	label: string;
}
@Component({
	selector: 'vl-form-dynamic',
	templateUrl: './vl-form-dynamic.component.html',
	styleUrls: ['./vl-form-dynamic.component.scss'],
})
export class VlFormDynamicComponent implements OnInit {
	@Input() data: any;
	@Input() value: any;
	@Output() valueChange = new EventEmitter<any>();
	formTypes: Array<SelectValues> = [];
	customTypes: Array<SelectValues> = [
		{
			value: 'select',
			label: 'Selección',
		},
		{
			value: 'yes-no',
			label: 'Si/No',
		},
		{
			value: 'regex',
			label: 'Expresión regular',
		},
		{
			value: 'download',
			label: 'Descarga',
		},
		{
			value: 'upload',
			label: 'Subida de archivo',
		},
		{
			value: 'textarea',
			label: 'Texto largo',
		},
		{
			value: 'text',
			label: 'Texto',
		},
		{
			value: 'number',
			label: 'Número',
		},
		{
			value: 'email',
			label: 'Correo electrónico',
		},
		{
			value: 'phone',
			label: 'Teléfono',
		},
	];
	subAbilitiesForms: Array<any> = [];
	firstTime: boolean = false;
	constructor(private uploadFileService: FileUploadService, private loadingService: LoadingService) {}
	ngOnChanges(changes: SimpleChanges) {
		// this.change();
	}
	ngOnInit(): void {
		this.formTypes = this.customTypes.reverse();

		// edit
		if (this.value) {
			this.value.forEach((item: any, index: number) => {
				this.subAbilitiesForms.push({});
				const form = new FormGroup<any>({
					title: new FormControl(item.label, [Validators.required, Validators.minLength(2), Validators.maxLength(120)]),
					required: new FormControl(item.required),
				});
				this.subAbilitiesForms[index].value = item._id;
				if (item._id == 'select') {
					this.subAbilitiesForms[index].options = [];
					this.subAbilitiesForms[index].optionNumber = 0;

					item.options.forEach((option: any, i: number) => {
						const optionNumber = this.subAbilitiesForms[index].optionNumber++;

						form.addControl(
							`option-${optionNumber}`,
							new FormControl(option, [Validators.required, Validators.minLength(2), Validators.maxLength(120)])
						);
						this.subAbilitiesForms[index].options.push({ value: optionNumber });
					});

					this.subAbilitiesForms[index].form = form;
				} else if (item._id == 'regex') {
					form.addControl('regex', new FormControl(item.regex as String, [Validators.required]));
					form.addControl('title', new FormControl(item.title as String, [Validators.required]));
					this.subAbilitiesForms[index].form = form;
				} else if (item._id == 'download') {
					this.subAbilitiesForms[index].form = form;
					this.subAbilitiesForms[index].download = item.download;
					this.subAbilitiesForms[index].filename = item.filename;
					this.setFileNameFromId(item.download, index);
				} else {
					this.subAbilitiesForms[index].form = form;
				}
			});
			if (!this.firstTime) {
				this.loadingService.hide();
			}
			this.firstTime = true;
			this.change();
		} else {
			this.subAbilitiesForms.push({
				value: 0,
				// required: false,
				form: new FormGroup({
					title: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]),
					required: new FormControl(false),
				}),
			});
		}
	}

	change() {
		for (const item of this.subAbilitiesForms) {
			if (item.form) {
				if (item.form.invalid) return this.valueChange.emit(null);
			}
		}
		if (this.subAbilitiesForms.some((item: any) => item.value == 0)) return this.valueChange.emit(null);

		this.valueChange.emit({
			subAbilitiesForms: this.subAbilitiesForms.map((item: any) => {
				let result;
				if (this.customTypes.some((type: any) => type.value == item.value)) {
					const data = {
						label: item.form.value.title,
						_id: item.value,
						required: item.form.value.required,
					};
					if (item.download != undefined && item.download != '') {
						result = {
							...data,
							download: item.download,
							filename: item.filename,
						};
					} else if (item.value != 'select' && item.value != 'download') {
						result = {
							...data,
							...item.form.value,
						};
					} else if (item.regex != '' && item.value == 'regex') {
						result = {
							...data,
							title: item.form.value.title,
							regex: item.form.value.regex,
						};
					} else {
						result = {
							...data,
							options: Object.keys(item.form.value)
								.filter((key: any) => key.includes('option'))
								.map((key: any) => item.form.value[key]),
						};
					}
					return result;
				}
			}),
		});
	}

	// form actions
	addForm() {
		this.subAbilitiesForms.push({
			value: 0,
			form: new FormGroup({
				title: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]),
				required: new FormControl(false),
			}),
		});
		this.change();
	}

	removeForm(index: number) {
		this.subAbilitiesForms.splice(index, 1);
	}

	changeOption(event: any, index: number) {
		this.subAbilitiesForms[index].value = event;
		const item = this.subAbilitiesForms[index];
		const form = this.subAbilitiesForms[index].form as FormGroup;
		if (event == 'download') {
			form.removeControl('regex');
			this.removeSelect(index);
			form.addControl('download', new FormControl('', [Validators.required]));
			item.progress = 0;
		} else if (event == 'select') {
			form.removeControl('download');
			form.removeControl('regex');
			this.removeSelect(index);
			this.subAbilitiesForms[index].options = [];
			this.subAbilitiesForms[index].optionNumber = 0;
			this.addOptionToSelect(index);
		} else if (event == 'regex') {
			form.removeControl('download');
			this.removeSelect(index);
			form.addControl('regex', new FormControl('', [Validators.required]));
		}
		this.change();
	}

	/**
	 * @description add option to select field
	 * @param fldIndex field index
	 */
	addOptionToSelect(fldIndex: number, value: string = '') {
		const optionNumber = this.subAbilitiesForms[fldIndex].optionNumber++;

		this.subAbilitiesForms[fldIndex].options.push({ value: optionNumber });
		this.subAbilitiesForms[fldIndex].form.addControl(
			`option-${optionNumber}`,
			new FormControl(value, [Validators.required, Validators.minLength(2), Validators.maxLength(120)])
		);
	}

	/**
	 * @description remove option from select field
	 *
	 * @param fldIndex field index
	 * @param optNumber  option index
	 */

	removeOptionFromSelect(fldIndex: number, optionIndex: number, optNumber: number) {
		this.subAbilitiesForms[fldIndex].options.splice(optionIndex, 1);
		this.subAbilitiesForms[fldIndex].form.removeControl(`option-${optNumber}`);
		this.change();
	}

	/**
	 * @description remove select field
	 * @param fldIndex field index
	 */
	removeSelect(fldIndex: number) {
		const fld = this.subAbilitiesForms[fldIndex];
		if (!fld.options) return;
		fld.options?.forEach((_: any, i: number) => {
			fld.form.removeControl(`option-${i}`);
		});
		fld.options = null;
		this.change();
	}
	/**
	 *
	 * @param event
	 * @param i
	 */
	changeRequired(event: any, i: number) {
		this.change();
	}

	// validations
	validateTimeInput(event: any) {
		const pattern = /[0-9]/;
		let inputChar = event.key;

		if (!pattern.test(inputChar) && inputChar != 'Backspace') {
			event.preventDefault();
		}
	}

	//file fields
	uploadFile(event: any, index: number) {
		const files = (event.target as HTMLInputElement).files;
		const form = this.subAbilitiesForms[index].form as FormGroup;
		if (!files?.length) return;
		const file = files[0];
		// validate file size less 10mb
		if (file.size > 10000000) {
			form?.get('download')?.setErrors({ size: true });
			return;
		}
		if (!this.validateFileFormat(file)) {
			form?.get('download')?.setErrors({ format: true });
			return;
		}
		this.uploadFileService.fileUpload('/easy/banner', file, 'image').subscribe(async (res: any) => {
			if (res instanceof HttpResponse) {
				// file uploaded
				form?.get('download')?.setValue(res.body._id);
				this.subAbilitiesForms[index].progress = 0;
				this.setFileNameFromId(res.body._id, index);

				this.change();
			} else if (res instanceof HttpErrorResponse) {
				// 			// server error or internet error
				form?.get('download')?.setErrors({ server: true });
			} else if (res.type === 1) {
				// 			// progress estatus
				this.subAbilitiesForms[index].progress = (res.loaded / res.total) * 100;
			}
		});
		this.change();
		delete files[0];
	}

	async setFileNameFromId(file: string, fldIndex: number) {
		const x: any = await lastValueFrom(this.uploadFileService.getFileDocument(file));
		this.subAbilitiesForms[fldIndex].filename = x.originalname;
		this.subAbilitiesForms[fldIndex].download = x._id;
		this.change();
	}

	validateFileFormat(file: File) {
		const formats = ['image', 'pdf', 'doc', 'docx', 'xlsx', 'xls'];
		for (const format of formats) if (file.type.includes(format)) return true;

		return false;
	}
}
