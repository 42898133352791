import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlFormGroupS2Component } from './vl-form-group-s2.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { VlSelectCustomModule } from '@components_shared/vl-select-custom/vl-select-custom.module';
import { VlIconModule } from '@components_shared/vl-icon/vl-icon.module';
import { FormInputTrimModule } from '@directives/form-input-trim/form-input-trim.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HkVlSelectModule } from '@components_shared/hk-vl-select/hk-vl-select.module';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
	declarations: [VlFormGroupS2Component],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		VlIconModule,
		VlSelectCustomModule,
		FormInputTrimModule,
		MatDatepickerModule,
		MatNativeDateModule,
		HkVlSelectModule,
	],
	exports: [VlFormGroupS2Component],
	providers: [MatDatepickerModule],
})
export class VlFormGroupS2Module {}
