<div class="content-main-menu">
	<div class="menu-items">
		<div
			id="menuLateralAttentions"
			class="action-menu-item"
			[routerLink]="['/dashboard/analytics']"
			routerLinkActive="isActive"
			[routerLinkActiveOptions]="{ exact: false }"
			*wTP="['co.anlAttention.all', 'co.anlAttention.view']"
		>
			<div class="menu-item-container">
				<div routerLinkActive="isActive" class="left-indicator"></div>
				<v-icon name="vl-esp-home" class="menu-item-container__icon" style="margin-right: 2px"></v-icon>
			</div>
			<span routerLinkActive="isActive" class="title-item-menu">Inicio</span>
		</div>
		<div
			id="menuLateralAttentions"
			class="action-menu-item"
			[routerLink]="['/dashboard/attentions']"
			routerLinkActive="isActive"
			[routerLinkActiveOptions]="{ exact: false }"
			*wTP="['co.conversation.all', 'co.conversation.list', 'co.conversation.listCurrent', 'co.conversation.listQueued']"
		>
			<div class="menu-item-container">
				<div routerLinkActive="isActive" class="left-indicator"></div>
				<v-icon name="atencion" class="menu-item-container__icon"></v-icon>
			</div>
			<span routerLinkActive="isActive" class="title-item-menu">Atención</span>
		</div>
		<div
			class="action-menu-item"
			[routerLink]="['/dashboard/channels']"
			routerLinkActive="isActive"
			[routerLinkActiveOptions]="{ exact: false }"
			*wTP="[
				'co.cc.all',
				'co.cc.personalize',
				'co.cc.integration',
				'co.app.all',
				'co.app.list',
				'co.whatsapp.all',
				'co.whatsapp.activation',
				'co.whatsapp.personalize',
				'co.facebook.all',
				'co.facebook.activation',
				'co.facebook.personalize',
				'co.instagram.all',
				'co.instagram.activation',
				'co.instagram.personalize',
				'co.wid.all',
				'co.wid.integration'
			]"
		>
			<div class="menu-item-container">
				<div routerLinkActive="isActive" class="left-indicator"></div>
				<v-icon name="canal" class="menu-item-container__icon"></v-icon>
			</div>
			<span routerLinkActive="isActive" class="title-item-menu">Canales</span>
		</div>
		<div
			class="action-menu-item"
			[routerLink]="['/dashboard/configurations']"
			routerLinkActive="isActive"
			[routerLinkActiveOptions]="{ exact: false }"
			*wTP="[
				'co.user.all',
				'co.user.list',
				'co.contact.all',
				'co.contact.list',
				'co.group.all',
				'co.group.list',
				'co.attentionType.all',
				'co.attentionType.list',
				'co.role.all',
				'co.role.list'
			]"
		>
			<div class="menu-item-container">
				<div routerLinkActive="isActive" class="left-indicator"></div>
				<v-icon name="ajuste" class="menu-item-container__icon"></v-icon>
			</div>
			<span routerLinkActive="isActive" class="title-item-menu">Ajustes</span>
		</div>
		<div
			class="action-menu-item"
			[routerLink]="['/dashboard/profile']"
			routerLinkActive="isActive"
			[routerLinkActiveOptions]="{ exact: false }"
		>
			<div class="menu-item-container">
				<div routerLinkActive="isActive" class="left-indicator"></div>
				<img [src]="'assets/svg/omniflou-white.svg'" sizes="cover" class="logo-menu" />
			</div>
			<span routerLinkActive="isActive" class="title-item-menu">Perfil</span>
		</div>
	</div>
</div>
