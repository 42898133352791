import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
	selector: 'hk-contact-form',
	templateUrl: './forms-contact-forms.component.html',
	styleUrls: ['./forms-contact-forms.component.scss'],
})
export class FormsContactFormsComponent implements OnInit {
	done: boolean = false;
	hasVerificationField: boolean = false;
	verificationFields: string[] = [];
	@Input() customFields: any[] = [];
	@Input() btnColor: string = '';
	sizeButton: string = '72px !important';
	constructor() {
		this.buildForm();
	}

	form = new FormGroup({});

	buildForm() {
		this.customFields.forEach((item) => {
			const validations = [];

			// required fields
			if (item.required) validations.push(Validators.required);
			// phone number fields
			if (item.type === 'phone') {
				this.hasVerificationField = true;
				this.verificationFields.push('phone');
			}

			if (item.type === 'email') {
				validations.push(Validators.email);
				this.hasVerificationField = true;
				this.verificationFields.push('email');
			}

			// only for number
			else if (['age', 'document'].includes(item.type)) {
				validations.push(Validators.pattern('^[0-9]*$'));
				validations.push(Validators.min(item.min));
				validations.push(Validators.max(item.max));
			}
			// only for letters
			else if (['lastname', 'city', 'name', 'fullname', 'country', 'profesion'].includes(item.type)) {
				validations.push(Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚ ]*$'));
				validations.push(Validators.maxLength(item.max));
				validations.push(Validators.minLength(item.min));
			} else {
				validations.push(Validators.pattern('^[a-zA-Z0-9áéíóúÁÉÍÓÚ ]*$'));
				validations.push(Validators.maxLength(item.max));
				validations.push(Validators.minLength(item.min));
			}

			this.form.addControl(item.type, new FormControl('', validations));
		});
		this.done = true;
	}
	ngOnInit(): void {}
}
